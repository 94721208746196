<template>
  <div>
    <el-form ref="form" :model="postForm" :rules="rules" class="profileInfo">
      <div class="profilePicWrap">
        <div>
          <div class="uploadText"><h3>{{disabled?"檢視":"上傳"}}照片</h3></div>
          <div class="uploadInput">
            <label for="upload">
              <input id="upload" type="file" @change="upLoadImg" :disabled="disabled"/>
              <img :src="imgfile" alt=""/>
            </label>
          </div>
        </div>
      </div>
      <div class="collapseWrap">
        <el-collapse v-model="activeNames">
          <!-- 人名 -->
          <div v-if="warningFormType === 'people'" class="peopleFromWrap">
            <el-collapse-item title="基本資料">
              <div class="BasicInfoWrap">
                <section class="nameInput">
                  <el-form-item label="名稱-姓" prop="Base.FirstName">
                    <el-input v-model.trim="postForm.Base.FirstName" placeholder="請輸入姓" :disabled="disabled"/>
                  </el-form-item>
                  <el-form-item label="名稱-中間名">
                    <el-input v-model.trim="postForm.Base.MiddleName" placeholder="請輸入中間名" :disabled="disabled"/>
                  </el-form-item>
                  <el-form-item label="名稱-名">
                    <el-input v-model.trim="postForm.Base.LastName" placeholder="請輸入名" :disabled="disabled"/>
                  </el-form-item>
                </section>
                <!-- 下拉選選 -->
                <section class="peopleSelect">
                  <div class="peopleSelect--customer">
                    <div class="peopleSelect--customer--content">
                      <el-form-item prop="Base.IsCustomer" label="是否為既有客戶">
                        <el-select v-model="postForm.Base.IsCustomer" placeholder="是否為既有客戶" :disabled="disabled">
                          <el-option v-for="item in customerSelectData" :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="peopleSelect--gender">
                    <el-form-item label="性別">
                      <el-select v-model="postForm.Base.Gender" placeholder="請選擇性別" :disabled="disabled">
                        <el-option v-for="item in sexSelectData" :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>

                  <div class="peopleSelect--attribute">
                    <el-form-item label="名單屬性" prop="Attribute">
                      <el-select v-model="postForm.Attribute" :disabled="disabled" value-key="ID" id="attribute" multiple placeholder="請選擇性別名單屬性">
                        <el-option v-for="item in AttrSelectData" :key="item"
                          :value="item"
                          :label="item"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </section>

                <section class="idNum">
                  <el-form-item label="身分證字號:">
                    <el-input v-model.trim="postForm.Base.IDNo1" :disabled="disabled" placeholder="請輸入身分證字號"/>
                  </el-form-item>

                  <el-form-item label="護照號碼:">
                    <el-input v-model.trim="postForm.Base.IDNo2" :disabled="disabled" placeholder="請輸入護照號碼"/>
                  </el-form-item>
                </section>

                <section class="birthdayAndBirthPlace">
                  <el-form-item label="出生地">
                    <el-input v-model.trim="postForm.Base.BirthPlace" placeholder="請輸入出生地" :disabled="disabled"/>
                  </el-form-item>
                  <div class="birthWrap">
                    <div class="itemTitle"><label for="gender">生日</label></div>
                    <el-date-picker :disabled="disabled" :editable="false" value-format="yyyy-MM-dd" type="date" placeholder="請選擇生日"
                      v-model="postForm.Base.Birthday"
                    ></el-date-picker>
                  </div>
                </section>

                <section class="nation">
                  <div class="loopWrap" v-for="item,index in postForm.Nation" :key="index">
                    <el-form-item :label="`國籍${index+1}`">
                      <el-input v-model.trim="item.Nation" :disabled="disabled" :placeholder="`請輸入國籍${index+1}`"/>
                    </el-form-item>
                    <el-button v-if="index == 0" :disabled="disabled" type="primary" icon="el-icon-plus" @click="addMultipleInput('Nation')"></el-button>
                    <el-button v-else :disabled="disabled" type="danger" icon="el-icon-delete" @click="deleteMultipleInput(index, 'Nation')"></el-button>
                  </div>
                </section>
              </div>
            </el-collapse-item>
            <el-collapse-item title="其他別名">
              <div class="otherNameWrap">
                <div class="multipleWrap">
                  <div v-for="item,index in postForm.TransName" :key="index" class="loopWrap">
                    <el-form-item :label="`翻譯名${index+1}`">
                      <el-input v-model.trim="item.TransName" :disabled="disabled" :placeholder="`請輸入翻譯名${index+1}`"/>
                    </el-form-item>
                    <el-button v-if="index == 0" :disabled="disabled" type="primary" icon="el-icon-plus" @click="addMultipleInput('TransName')"></el-button>
                    <el-button v-else :disabled="disabled" type="danger" icon="el-icon-delete" @click="deleteMultipleInput(index, 'TransName')"></el-button>
                  </div>
                </div>
                <div class="multipleWrap">
                  <div v-for="item,index in postForm.OtherName" :key="index" class="loopWrap">
                    <el-form-item :label="`其他稱呼${index+1}`">
                      <el-input v-model.trim="item.OtherName" :disabled="disabled" :placeholder="`請輸入其他稱呼${index+1}`"/>  
                    </el-form-item>
                    <el-button v-if="index == 0" :disabled="disabled" type="primary" icon="el-icon-plus" @click="addMultipleInput('OtherName')"></el-button>
                    <el-button v-else :disabled="disabled" type="danger" icon="el-icon-delete" @click="deleteMultipleInput(index, 'OtherName')"></el-button> 
                  </div>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item title="就職紀錄">
              <div class="workWrap">
                <div v-for="item,index in postForm.Work" :key="index" class="loopWrap">
                
                  <div>
                    <div class="inputContent">
                      <el-form-item :label="`職業${index+1}`">
                        <el-input v-model.trim="item.Occupation" :disabled="disabled" :placeholder="`請輸入職業${index+1}`"/>
                      </el-form-item>
                      <el-form-item :label="`職稱${index+1}`">
                        <el-input v-model.trim="item.Title" :disabled="disabled" :placeholder="`請輸入職稱${index+1}`"/>
                      </el-form-item>
                    </div>

                    <div class="dateContent">
                      <div class="dateWrap">
                        <div class="itemTitle"><label for="gender">就職日</label></div>
                        <el-date-picker type="date" :editable="false" placeholder="請選擇就職日" value-format="yyyy-MM-dd"
                          :disabled="disabled"
                          v-model="item.ArrivedDt"
                        ></el-date-picker>
                      </div>

                      <div class="dateWrap">
                        <div class="itemTitle"><label for="gender">離職日</label></div>
                        <el-date-picker  type="date" :editable="false" placeholder="請選擇離職日" value-format="yyyy-MM-dd"
                          :disabled="disabled"
                          v-model="item.LeavedDt"
                        ></el-date-picker>
                      </div>
                    </div>
                  </div>
                  <el-button v-if="index == 0" :disabled="disabled" type="primary" icon="el-icon-plus"
                    @click="addMultipleInput('Work')" 
                  ></el-button>
                  <el-button v-else :disabled="disabled" type="danger" icon="el-icon-delete"
                    @click="deleteMultipleInput(index, 'Work')"
                  ></el-button>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item title="聯絡資訊">
              <div class="contact">
                <div class="multipleWrap">
                  <div class="loopWrap" v-for="item,index in postForm.Contact" :key="index">
                    <el-form-item :label="`聯絡方式${index+1}`">
                      <el-input v-model.trim="item.Contact" :disabled="disabled" :placeholder="`請輸入聯絡方式${index+1}`"/>
                    </el-form-item>
                    <el-button v-if="index == 0" :disabled="disabled" type="primary" icon="el-icon-plus" @click="addMultipleInput('Contact')"></el-button>
                    <el-button v-else :disabled="disabled" type="danger" icon="el-icon-delete" @click="deleteMultipleInput(index, 'Contact')"></el-button>
                  </div>
                </div>
                <div class="multipleWrap">
                  <div class="loopWrap" v-for="item,index in postForm.Address" :key="index">
                    <el-form-item :label="`居住地${index+1}`">
                      <el-input v-model.trim="item.Place" :disabled="disabled" :placeholder="`請輸入居住地${index+1}`"/>
                    </el-form-item>
                    <el-button v-if="index == 0" :disabled="disabled" type="primary" icon="el-icon-plus" @click="addMultipleInput('Address')"></el-button>
                    <el-button v-else type="danger" :disabled="disabled" icon="el-icon-delete" @click="deleteMultipleInput(index, 'Address')"></el-button>
                  </div>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item title="關聯資訊">
              <div class="relationInfo">
                <div v-for="item,index in postForm.Relation" :key="index" class="loopWrap">
                  <div class="inputContent">
                    <el-form-item :label="`關聯名稱${index+1}`">
                      <el-input v-model.trim="item.RelEntity" :disabled="disabled" :placeholder="`請輸入關聯名稱${index+1}`"/>
                    </el-form-item>
                    <el-form-item :label="`關聯關係${index+1}`">
                      <el-input v-model.trim="item.RelMemo" :disabled="disabled" :placeholder="`請輸入關聯關係${index+1}`"/>
                    </el-form-item>
                    <el-button v-if="index == 0" :disabled="disabled" type="primary" icon="el-icon-plus" @click="addMultipleInput('Relation')"></el-button>
                    <el-button v-else :disabled="disabled" type="danger" icon="el-icon-delete" @click="deleteMultipleInput(index, 'Relation')"></el-button>
                  </div>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item title="備註說明" name="3">
              <el-input type="textarea" :disabled="disabled" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="請輸入内容"
                v-model.trim="postForm.Base.Memo"
              ></el-input>
            </el-collapse-item>
          </div>
          <!-- 組織 -->
          <div v-if="warningFormType === 'org'" class="orgFromWrap">
            <!-- **組織表單** -->
            <el-collapse-item title="基本資料" name="2">
              <div>
                <!-- 基本資料 -->
                <div class="BasicInfoWrap">
                  <section class="nameInput">
                    <el-form-item label="公司中文名稱：" prop="Base.ComCnName">
                      <el-input v-model="postForm.Base.ComCnName" :disabled="disabled" placeholder="請輸入公司中文名稱"/>
                    </el-form-item>
                    <el-form-item label="公司英文名稱：">
                      <el-input v-model="postForm.Base.ComEngName" :disabled="disabled" placeholder="請輸入公司英文名稱"/>
                    </el-form-item>
                    <el-form-item label="代表人：">
                      <el-input v-model="postForm.Base.RepName" :disabled="disabled" placeholder="請輸入代表人"/>
                    </el-form-item>
                  </section>
                  <!-- 下拉選選 -->
                  <section class="orgSelect">
                    <div class="orgSelect--customer">
                      <div class="orgSelect--customer--content">
                        <el-form-item prop="Base.IsCustomer" label="是否為既有客戶：">
                          <el-select v-model="postForm.Base.IsCustomer" placeholder="是否為既有客戶" :disabled="disabled">
                            <el-option v-for="item in customerSelectData" :key="item.value"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                    </div>

                    <!-- 名單屬性 -->
                    <div class="orgSelect--attribute">
                      <el-form-item label="名單屬性：" prop="Attribute">
                        <el-select v-model="postForm.Attribute" :disabled="disabled" value-key="ID" id="attribute" multiple placeholder="請選擇性別名單屬性">
                          <el-option v-for="item in AttrSelectData" :key="item"
                            :value="item"
                            :label="item"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </section>
                  
                  <section class="uniComNmAndApvDt">
                    <el-form-item label="統一編號：" prop="Base.UniComNm">
                      <el-input v-model.trim="postForm.Base.UniComNm" :disabled="disabled" placeholder="請輸入統一編號"/>
                    </el-form-item>
                    <div class="apvDtWrap">
                      <p class="subtitle"><label for="gender">設立日期：</label></p>
                      <el-date-picker :disabled="disabled" :editable="false" value-format="yyyy-MM-dd" type="date" placeholder="請選擇設立日期"
                        v-model="postForm.Base.ApvDt" 
                      ></el-date-picker>
                    </div>
                    <el-form-item label="註冊地：">
                      <el-input v-model.trim="postForm.Base.BirthPlace" placeholder="請輸入註冊地" :disabled="disabled"/>
                    </el-form-item>
                  </section>

                  <section class="nationAndIndus">
                    <div class="multipleWrap">
                      <div class="loopWrap" v-for="item,index in postForm.OrgIndus" :key="index">
                        <el-form-item :label="`經營產業${index+1}`">
                          <el-input v-model.trim="item.Indus" :disabled="disabled" :placeholder="`請輸入經營產業${index+1}`"/>
                        </el-form-item>
                        <el-button v-if="index == 0" :disabled="disabled" type="primary" icon="el-icon-plus" @click="addMultipleInput('OrgIndus')"></el-button>
                        <el-button v-else :disabled="disabled" type="danger" icon="el-icon-delete" @click="deleteMultipleInput(index, 'OrgIndus')"></el-button>
                      </div>
                    </div>
                    <div class="multipleWrap">
                      <div class="loopWrap" v-for="item,index in postForm.Nation" :key="index">
                        <el-form-item :label="`國籍${index+1}`">
                          <el-input v-model.trim="item.Nation" :disabled="disabled" :placeholder="`請輸入國籍${index+1}`"/>
                        </el-form-item>
                        <el-button v-if="index == 0" :disabled="disabled" type="primary" icon="el-icon-plus" @click="addMultipleInput('Nation')"></el-button>
                        <el-button v-else :disabled="disabled" type="danger" icon="el-icon-delete" @click="deleteMultipleInput(index, 'Nation')"></el-button>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item title="其他別名">
              <div class="otherNameWrap">
                <div class="multipleWrap">
                  <div v-for="item,index in postForm.TransName" :key="index" class="loopWrap">
                    <el-form-item :label="`其他翻譯名稱${index+1}`">
                      <el-input v-model.trim="item.TransName" :disabled="disabled" :placeholder="`請輸入其他翻譯名稱${index+1}`"/>
                    </el-form-item>
                    <el-button v-if="index == 0" :disabled="disabled" type="primary" icon="el-icon-plus" @click="addMultipleInput('TransName')"></el-button>
                    <el-button v-else :disabled="disabled" type="danger" icon="el-icon-delete" @click="deleteMultipleInput(index, 'TransName')"></el-button>
                  </div>
                </div>
                <div class="multipleWrap">
                  <div v-for="item,index in postForm.OtherName" :key="index" class="loopWrap">
                    <el-form-item :label="`公司簡稱${index+1}`">
                      <el-input v-model.trim="item.OtherName" :disabled="disabled" :placeholder="`請輸入公司簡稱${index+1}`"/>  
                    </el-form-item>
                    <el-button v-if="index == 0" :disabled="disabled" type="primary" icon="el-icon-plus" @click="addMultipleInput('OtherName')"></el-button>
                    <el-button v-else :disabled="disabled" type="danger" icon="el-icon-delete" @click="deleteMultipleInput(index, 'OtherName')"></el-button> 
                  </div>
                </div>
                </div>
            </el-collapse-item>
            <el-collapse-item title="變更紀錄">
              <div class="OrgRecordWrap">
                <div v-for="item,index in postForm.OrgRecord" :key="index" class="loopWrap">
                  <div class="content">
                    <el-form-item :label="`公司變更/停業/結束項目${index+1}`">
                      <el-input v-model.trim="item.ChangeItem" :disabled="disabled" :placeholder="`請輸入公司變更/停業/結束項目${index+1}`"/>
                    </el-form-item>
                    <div class="dateWrap">
                      <div class="itemTitle"><label>公司變更/停業/結束日期</label></div>
                      <el-date-picker type="date" :editable="false" placeholder="公司變更/停業/結束日期" value-format="yyyy-MM-dd"
                        :disabled="disabled"
                        v-model="item.ChangeDt"
                      ></el-date-picker>
                    </div>
                  </div>
                  <el-button v-if="index == 0" :disabled="disabled" type="primary" icon="el-icon-plus"
                    @click="addMultipleInput('OrgRecord')" 
                  ></el-button>
                  <el-button v-else :disabled="disabled" type="danger" icon="el-icon-delete"
                    @click="deleteMultipleInput(index, 'OrgRecord')"
                  ></el-button>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item title="聯絡資訊">
              <div class="contact">
                <div class="multipleWrap">
                  <div class="loopWrap" v-for="item,index in postForm.Contact" :key="index">
                    <el-form-item :label="`聯絡方式${index+1}`">
                      <el-input v-model.trim="item.Contact" :disabled="disabled" :placeholder="`聯絡方式${index+1}`"/>
                    </el-form-item>
                    <el-button v-if="index == 0" :disabled="disabled" type="primary" icon="el-icon-plus" @click="addMultipleInput('Contact')"></el-button>
                    <el-button v-else :disabled="disabled" type="danger" icon="el-icon-delete" @click="deleteMultipleInput(index, 'Contact')"></el-button>
                  </div>
                </div>
                <div class="multipleWrap">
                  <div class="loopWrap" v-for="item,index in postForm.Address" :key="index">
                    <el-form-item :label="`營運地${index+1}`">
                      <el-input v-model.trim="item.Place" :disabled="disabled" :placeholder="`營運地${index+1}`"/>
                    </el-form-item>
                    <el-button v-if="index == 0" :disabled="disabled" type="primary" icon="el-icon-plus" @click="addMultipleInput('Address')"></el-button>
                    <el-button v-else type="danger" :disabled="disabled" icon="el-icon-delete" @click="deleteMultipleInput(index, 'Address')"></el-button>
                  </div>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item title="關聯資訊">
              <div class="relationInfo">
                <div v-for="item,index in postForm.Relation" :key="index" class="loopWrap">
                  <div class="content">
                    <el-form-item :label="`關聯公司${index+1}`">
                      <el-input v-model.trim="item.RelEntity" :disabled="disabled" :placeholder="`關聯公司${index+1}`"/>
                    </el-form-item>
                    <el-form-item :label="`關聯關係${index+1}`">
                      <el-input v-model.trim="item.RelMemo" :disabled="disabled" :placeholder="`關聯關係${index+1}`"/>
                    </el-form-item>
                  </div>
                  <el-button v-if="index == 0" :disabled="disabled" type="primary" icon="el-icon-plus" @click="addMultipleInput('Relation')"></el-button>
                  <el-button v-else :disabled="disabled" type="danger" icon="el-icon-delete" @click="deleteMultipleInput(index, 'Relation')"></el-button>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item title="備註說明" name="3">
              <el-input type="textarea" :disabled="disabled" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="請輸入内容"
                v-model.trim="postForm.Base.Memo"
              ></el-input>
            </el-collapse-item>
          </div> 
        </el-collapse>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    data:{
      type:Object
    },
    status:{
      type: String,
    },
    formType:{
      type: String,
    },
    uploadImgFlag:{
      type: Boolean,
    }
  },
  computed:{
    disabled(){
      let result = null;
      if(this.status==='addStatus'||this.status==='editStatus'){
        result = false
      }else if(this.status==='previewStatus'){
        result = true
      }
      return result
    },
    warningFormType(){
      return this.formType
    }
  },
  data() {
    return {
      imgfile:require("../assets/002709f8aab3895a.jpeg"),
      activeNames: ["1","2"],
      imgFileObj:"",
      customerSelectData:[
        {
          label:'是',
          value:"Ture"
        },
        {
          label:'否',
          value:"False"
        },
        {
          label:'不確定',
          value:"Unknow"
        },
      ],
      sexSelectData:[
        {
          label:'男',
          value:"Male"
        },
        {
          label:'女',
          value:"Female"
        },
        {
          label:'不確定',
          value:"Unknow"
        },
      ],
      AttrSelectData: [],
      rules: {
        'Base.FirstName': [
          {required: true, message: '請输入姓名', trigger: 'blur' }
        ],
        'Base.ComCnName': [
          {required: true, message: '請输入公司中文名稱', trigger: 'blur' }
        ],
        "Base.IsCustomer": [
          {required: true, message: '請選擇既有客戶', trigger: ['blur','change'] }
        ],
        "Base.UniComNm": [
          // {required: 'number', message: '請輸入數字', trigger: ['blur','change'] },
          {max: 8, message: "統一編號最多8碼", trigger: ['blur','change']}
        ],
        Attribute:[
          {required: true, message: '請選名單屬性', trigger: ['blur','change'] }
        ]
      },
      postForm: {
        // UserID: "",
        // ListID: "",
        Base: {
          /*共用*/
            IsCustomer: "", //是否為既有客戶
            Memo: "",//備註
            BirthPlace: "", //出生地//註冊地
            Gender: "", //性別
            // PicUrl: "", //上傳照片,新增編輯時不會有,get資料時才有此欄位
          /*人名*/
            LastName: "", //名單名稱-名
            MiddleName: "", //名單名稱-中間名
            FirstName: "", //名單名稱-姓
            IDNo1: "", //身分證字號
            IDNo2: "", //護照號碼
            Birthday: null, //生日
          /*組織*/
            ComCnName: "", //公司中文名稱
            ComEngName: "", //公司英文名稱
            UniComNm: "", //統一編號
            RepName: "", //代表人
            ApvDt: null, //設立日期
        },
        Attribute: [],//名單屬性
        Nation: [//國家(必)
          {
            ID: "",
            Nation: "",
          },
        ],
        TransName: [//翻譯名(必)
          {
            ID: "",
            TransName:''
          },
        ],
        OtherName: [//其他稱呼/公司簡稱(必)
          {
            ID: "",
            OtherName: "",
          },
        ],
        Work: [//就職紀錄
          {
            ID: "",
            Occupation: "",
            Title: "",
            ArrivedDt: "",
            LeavedDt: "",
          },
        ],
        OrgRecord: [//變更紀錄
          {
            ID: "",
            ChangeDt: "",
            ChangeItem: "",
          },
        ],
        OrgIndus: [//經營產業
          {
            ID: "",
            Indus: "",
          },
        ],
        Contact: [//聯絡方式
          {
            ID: "",
            Contact: "",
          },
        ],
        Address: [//居住地
          {
            ID: "",
            Place: "",
          },
        ],
        Relation: [//關聯資訊
          {
            ID: "",
            RelEntity: "",
            RelMemo: "",
          },
        ],
      },
    };
  },
  watch:{
    data:{
      handler(data){
        console.log('watch:data');
        if(JSON.stringify(data)!=='{}'){  
          let handleData = JSON.parse(JSON.stringify(data));
          let keys = Object.keys(handleData.Base)
          keys.forEach((key)=>{
            if(!!handleData.Base[key]){
              this.postForm.Base[key] = handleData.Base[key]
            }
          })
          if(!!handleData.Base.PicBase64){
            this.imgfile = `data:image/png;base64,${this.postForm.Base.PicBase64}`
          }else{
            this.imgfile = require("../assets/002709f8aab3895a.jpeg")
          }
          let {Attribute,Nation,TransName,OtherName,Work,OrgRecord,OrgIndus,Contact,Address,Relation} = {...handleData};
          this.postForm.Attribute = Attribute
          this.postForm.Nation = Nation
          this.postForm.TransName = TransName
          this.postForm.OtherName = OtherName
          this.postForm.Work = Work
          this.postForm.OrgRecord = OrgRecord
          this.postForm.OrgIndus = OrgIndus
          this.postForm.Contact = Contact
          this.postForm.Address = Address
          this.postForm.Relation = Relation
        }
      },
      deep:true,
      // immediate:true
    },
    uploadImgFlag:{
      handler(val){
        if(val && !!this.imgFileObj){
          this.$emit('handleImgUpload',this.imgFileObj)
        }
      }
    }
  },
  methods: {
    GetAttributeSelectData(){
      //撈下拉選單
      this.$api.system.getOptions({Type:["ListAttribute"]}).then((res)=>{
        this.AttrSelectData = res.ListAttribute
      })
    },
    validForm() {
      let result = false
      this.$refs['form'].validate((valid) => { result = valid })
      return result
    },
    upLoadImg(event) {//圖片上傳
      if (event.target.files) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.imgfile = e.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
        let fd = new FormData()
        fd.append("files", event.target.files[0]);
        this.imgFileObj = fd
      }
    },
    addMultipleInput(inputType) {
      switch (inputType) {
        case "Nation":
          this.postForm.Nation.push({
            ID: "",
            Nation: "",
          });
          break;
        case "TransName":
          this.postForm.TransName.push({
            ID: "",
            TransName: "",
          });
          break;
        case "OtherName":
          this.postForm.OtherName.push({
            ID: "",
            OtherName: "",
          });
          break;
        case "Work":
          this.postForm.Work.push({
            ID: "",
            Occupation: "",
            Title: "",
            ArrivedDt: "",
            LeavedDt: "",
          });
          break;
        case "Contact":
          this.postForm.Contact.push({
            ID: "",
            Contact: "",
          });
          break;
        case "Address":
          this.postForm.Address.push({
            ID: "",
            Place: "",
          });
          break;
        case "Relation":
          this.postForm.Relation.push({
            ID: "",
            RelEntity: "",
            RelMemo: "",
          });
          break;
        case "OrgRecord":
          this.postForm.OrgRecord.push({
            ID: "",
            ChangeDt: "",
            ChangeItem: "",
          });
          break;
        case "OrgIndus":
          this.postForm.OrgIndus.push({
            ID: "",
            Indus: "",
          });
          break;
      }
    },
    deleteMultipleInput(idx, typeName) {
      this.postForm[typeName].splice(idx,1)
    },
  },
  mounted() {
    this.GetAttributeSelectData();

    //判斷router的query有無routerName的值,如果有即代表是從詳細頁過來的,把詳細頁新聞的資料帶入到表單
    if(!!this.$route.query.routerName){
      let routerName = this.$route.query.routerName;
      let warningFormType = this.warningFormType;
      let typeNews = this.$route.query.typeNews;
      
      switch(routerName){
        case 'detailNews':
          if(warningFormType==='people'){
              this.postForm.Base.FirstName = this.$route.query.tagName;
          }else if(warningFormType==='org'){
              this.postForm.Base.ComCnName = this.$route.query.tagName;
          }
          break
        case 'tableDetailNews':
          let allNewsData = JSON.parse(this.$route.query.DataID);
          console.log(allNewsData);
          if(typeNews==='searChsan'){
              //打api撈回聯合國所有資料
              let listQuery = {
                DataID:[allNewsData.searChsan[0].DataID],
                Page_Size: 1, //每頁有幾筆
                Page_Num: 1, //目前頁數
              };
              this.$api.data.searChsan(listQuery).then((res)=>{
                let {SanList,SanList_Alias,SanList_Nationality,SanList_Title,SanList_Address,SanList_Birth} = {...res.data[0]}
                if(SanList_Nationality.length>0){
                    this.postForm.Nation = [];
                    SanList_Nationality.forEach((item)=>{
                      if(item.Nationality){
                        this.postForm.Nation.push({
                          ID: "",
                          Nation: item.Nationality,
                        })
                      }
                    })
                }
                if(SanList_Alias.length>0){
                  this.postForm.OtherName = [];
                  SanList_Alias.forEach((item)=>{
                    if(item.AliasName){
                      this.postForm.OtherName.push({
                        ID: "",
                        OtherName: item.AliasName
                      })
                    }
                  })
                }
                if(SanList_Address.length>0){
                  this.postForm.Address = [];
                  SanList_Address.forEach((item)=>{
                    if(item.City){
                      this.postForm.Address.push({
                        ID: "",
                        Place: item.City,
                      })
                    }
                  })
                }
                if(warningFormType==='people'){
                  this.postForm.Base.LastName = SanList.FirstName
                  this.postForm.Base.Gender = SanList.Gender
                  
                  if(SanList_Title.length>0){
                    this.postForm.Work = [];
                    SanList_Title.forEach((item)=>{
                      if(item.Title){
                        this.postForm.Work.push({
                          ID: "",
                          Occupation: "",
                          Title: item.Title,
                          ArrivedDt: "",
                          LeavedDt: "",
                        })
                      }
                    })
                  }
                }else if(warningFormType==='org'){
                  this.postForm.Base.ComEngName = SanList.FirstName
                  
                }
              })
          }
          if(typeNews==='searchConstr' && warningFormType==='org'){
            this.postForm.Base.UniComNm = allNewsData.searchConstr[0].UniComNm
            this.postForm.Base.ComCnName = allNewsData.searchConstr[0].ComName
            this.postForm.Base.BirthPlace = allNewsData.searchConstr[0].ComLoc
            this.postForm.Nation[0].Nation = allNewsData.searchConstr[0].ComCountry
          }
          if(typeNews==='searchLabor' && warningFormType==='org'){
            this.postForm.Base.UniComNm = allNewsData.searchLabor[0].UnitEntity
            this.postForm.Base.RepName = allNewsData.searchLabor[0].NameEntity
          }
          if(typeNews==='searchEnviroment' && warningFormType==='org'){
             this.postForm.Base.UniComNm = allNewsData.searchEnviroment[0].UnitName
          }
          break
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.profileInfo {
  display: flex;
  padding: 5%;
  text-align: center;
  justify-content: center;
  li,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  width: 90%;
  margin: auto;
  .profilePicWrap {
    width: 30%;
    .uploadText {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .uploadInput {
      margin-right: 10px;
      width:300px;
      height: 300px;
      margin: auto;
      //position: relative;
      label {
        display: block;
        cursor: pointer;
        img {
          max-height: 100%;  
          max-width: 100%; 
          width: auto;
          height: auto;
          //position: absolute;  
          top: 0;  
          bottom: 0;  
          left: 0;  
          right: 0;  
          margin: auto;
        }
      }
      #upload {
        display: none;
      }
    }
  }
  .collapseWrap {
    width: 60%;
    ::v-deep .el-collapse {
      .el-collapse-item__header {
        font-size: 20px;
        padding-left: 10px;
      }

      .el-collapse-item__header {
        background-color: #00abb9;
        color: white;
      }
    }
    //人名表單
    ::v-deep .peopleFromWrap {
      // border: 1px solid black;
      .inputTitle {
        display: flex;
        span{
          width: auto;
        }
      }
      // 基本資料
      .BasicInfoWrap {
        padding:0px 10px;
        .nameInput {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 20px;
          .el-form-item{
            width:30%;
            margin-right: 20px;
            margin-bottom: 0px;
          }
        }
        // 下拉選單
        .peopleSelect {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 20px;
          &--customer,&--gender,&--attribute{
            width: 30%;
            margin-right: 20px;
            .inputTitle{
              label{
                line-height: 40px;
              }
            }
            .el-select{
              width: 100%;
            }
          }
        }
        .idNum{
          display: flex;
          margin-bottom: 20px;
          .el-form-item{
            width: 30%;
            margin-right: 20px;
            margin-bottom: 0px;
          }
        }
        .birthdayAndBirthPlace{
          display: flex;
          margin-bottom: 20px;
          justify-content: flex-start;
          align-items: flex-end;
          .birthWrap{
            width: 30%;
            .itemTitle{
              text-align: left;
              label{
                line-height: 40px;
              }
            }
            .el-date-editor{
              width: 100%;
            }
          }
          .el-form-item{
            width: 30%;
            margin-bottom: 0px;
            margin-right: 20px;
          }
        }
       
        
        .nation {         
          display: flex;
          flex-wrap: wrap;
          .loopWrap{
            width: 30%;
            display: flex;
            margin-right: 20px;
            align-items: flex-end;
            .el-form-item{
              margin-bottom: 0px;
            }
          }
        }
      }
      //其他別名,聯絡資訊
      .otherNameWrap,.contact{
        // 其他別名
        .multipleWrap{
          display: flex;
          flex-wrap: wrap;
          margin-right: 20px;
          width: 100%;
          &:first-child{
            margin-bottom: 20px;
          }
          .loopWrap{
            display: flex;
            align-items: flex-end;
            margin-right: 20px;
            .el-form-item{
              margin-bottom: 0px;
            }
          }
        }
      }
      .workWrap,.relationInfo{
        display: flex;
        flex-wrap: wrap;
        .loopWrap{
          width: 100%;
          margin-bottom: 25px;
          display: flex;
          align-items: center;
          .inputContent,.dateContent{
            width: 100%;
            display: flex;
            .el-form-item{
              width: 40%;
              margin-right: 20px;
              margin-bottom: 0px;
            }
          }
          .inputContent{
            align-items: self-end;
          }
          .dateWrap{
            width: 40%;
            margin-right: 20px;
            .el-date-editor{
              width: 100%;
            }
            .itemTitle{
              text-align: left;
              label{
                line-height: 40px;
              }
            }
          }
        }
      }     
      .block {
        display: block;
      }
    }
    //組織表單
    ::v-deep .orgFromWrap{
     
      // 基本資料
      .BasicInfoWrap {
        .nameInput {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 20px;
          .el-form-item{
            width:30%;
            margin-right: 20px;
            margin-bottom: 0px;
          }
        }
        // 下拉選單
        .orgSelect {
          display: flex;
          justify-content: flex-start;
          &--customer,&--gender,&--attribute{
            width: 30%;
            margin-right: 20px;
            .inputTitle{
              label{
                line-height: 40px;
              }
            }
            .el-select{
              width: 100%;
            }
          }
        }
        .uniComNmAndApvDt{
          display: flex;
          margin-bottom: 20px;
          .el-form-item{
            width: 30%;
            margin-right: 20px;
            margin-bottom: 0px;
          }
          .apvDtWrap{
            width: 30%;
            margin-right: 20px;
            .subtitle{
              margin: 0px;
              text-align: left;
              line-height: 40px;
            }
            .el-date-editor{
              width: 100%;
            }
          }
        } 
        .nationAndIndus {
          .multipleWrap{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            &:first-child{
              margin-bottom: 20px;
            }
            .loopWrap{
              width: 30%;
              display: flex;
              align-items:self-end;
              margin-right: 20px;
              .el-form-item{
                margin-bottom: 0px;
              }
          }
          }
        }
      }
      //其他別名,聯絡資訊
      .otherNameWrap,.contact{
        // 其他別名
        .multipleWrap{
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          &:first-child{
            margin-bottom: 20px;
          }
          .loopWrap{
            display: flex;
            width: 30%;
            margin-right: 20px;
            align-items:self-end;
            .el-form-item{
              margin-bottom: 0px;
            }
          }
        }
      }
      .OrgRecordWrap,.relationInfo{
        display: flex;
        flex-wrap: wrap;
        .loopWrap{
          width: 100%;
          margin-bottom: 25px;
          display: flex;
          align-items: self-end;
          // flex-direction: column;
          .content{
            width: 80%;
            display: flex;
            .el-form-item{
              width: 50%;
              margin-right: 20px;
              margin-bottom: 0px;
            }
            .dateWrap{
              width: 50%;
              margin-right: 20px;
              .el-date-editor{
                width: 100%;
              }
              .itemTitle{
                text-align: left;
                label{
                  line-height: 40px;
                }
              }
            }
          }
          .el-button{
            width: fit-content;
          }
          
        }
      }
     
    }
  }
}
</style>
