<template>
    <div class="approvalRecord">
        <!-- 簽核歷程-->
        <section v-if="recordData.length!==0">
            <div class="recordWrap">
                <p>簽核歷程：</p>
                <div class="content"> 
                    <div class="title">
                        <p class="itemTitle">申請人/覆核人</p>
                        <p class="itemTitle">送審時間/覆核時間</p>
                        <p class="itemTitle">備註</p>
                    </div>
                    <div class="text" v-for="item,index in recordData" :key="index"> 
                        <div class="itemText">{{item.CreateUser?item.CreateUser:"此使用者無設定名字"}}</div>
                        <div class="itemText">{{filterDate(item.CreateDt)}}</div>
                        <div class="itemText">{{item.Memo?item.Memo:"無"}}</div>
                    </div>
                </div>
            </div>
        </section>
        <el-form :model="suggestion" v-if="routeName!=='relatedNews'">
            <section v-if="(caseStatus==='edit' && role==='operator' && permission==='True')||caseStatus==='add'">
                <!-- 承辦人送審備註 -->
                <div class="suggestionWrap">
                    <div>
                        <p>送審備註：</p>
                        <textarea v-model.trim="suggestion.operator" name="" id="" cols="30" rows="10" class="text"></textarea>
                    </div>
                </div>
            </section>
            <section v-if="(caseStatus==='wait_approval' && role==='manager')||(caseStatus==='wait_delete' && role==='manager')">
                <!-- 管理員簽核意見-->
                <div class="suggestionWrap">
                    <div>
                        <p>簽核意見：</p>
                        <textarea v-model.trim="suggestion.manager" name="" id="" cols="30" rows="10" class="text"></textarea>
                    </div>
                </div>
            </section>
        </el-form>
    </div>
</template>

<script>
    export default {
        props: {
            caseStatus:{
                type:String
            },
            role:{  
                type:String
            },
            data:{
                type:String
            }
        },
        data() {
            return {
                permission:this.$route.query.permission,
                routeName:this.$route.name,
                suggestion:{
                    operator:"",    
                    manager:""
                },
                requestData:{
                    ListID:this.$route.query.listID,
                    Page_Size:99,
                    Page_Num:1,
                },
                recordData:[]
            }
        },
        watch:{
            data(val){
                console.log(val);
                if(!val){
                    this.suggestion.operator = ''
                }else{
                    this.suggestion.operator = val
                }
            }
        },
        computed:{
            filterDate() {
                return (date) => {
                    return this.$dayjs(date).format("YYYY-MM-DD HH:mm:ss");
                };
            },
        },
        methods: {
            getApprovalRecord(){
                this.$api.record.getPreviewList(this.requestData).then((res)=>{
                    this.recordData = res.data
                    console.log(res);
                })
            }
        },
        mounted() {
            if(this.caseStatus!=='add'){
                this.getApprovalRecord()
            }
        },  
    }
</script>

<style lang="scss" scoped>
    .approvalRecord{
        width: 90%;
        margin: auto;
        display: flex;
        justify-content: space-evenly;
        .recordWrap{
            width: 100%;
            p{
                margin: 0px;
            }
            .content{
                width: 600px;
                border: 1px solid #000000;
                margin-top: 10px;
                max-height: 300px;
                overflow-y: scroll;
                .title,.text{
                    display: flex;
                }
                .text{
                    
                    border-bottom: 1px solid black;
                    &:last-child{
                        border-bottom: 0px
                    }
                }
                .itemTitle{
                    &:nth-child(1),&:nth-child(2){
                        border-right: 1px solid black;
                    }
                    width: 100%;
                    text-align: center;
                    padding: 8px 0px;
                    background-color: #00abb9;;
                }
                .itemText{
                    &:nth-child(1),&:nth-child(2){
                        border-right: 1px solid black;
                    }
                    width: 100%;
                    padding: 8px 0px;
                    text-align: center;
                }
            }
        }
        .suggestionWrap{
            width: 100%;
            p{
                margin: 0px;
            }
            .text{
                width: 600px;
                margin-top: 10px;
                padding: 0px;
            }
        }
    }
</style>