var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "profileInfo",
          attrs: { model: _vm.postForm, rules: _vm.rules }
        },
        [
          _c("div", { staticClass: "profilePicWrap" }, [
            _c("div", [
              _c("div", { staticClass: "uploadText" }, [
                _c("h3", [
                  _vm._v(_vm._s(_vm.disabled ? "檢視" : "上傳") + "照片")
                ])
              ]),
              _c("div", { staticClass: "uploadInput" }, [
                _c("label", { attrs: { for: "upload" } }, [
                  _c("input", {
                    attrs: {
                      id: "upload",
                      type: "file",
                      disabled: _vm.disabled
                    },
                    on: { change: _vm.upLoadImg }
                  }),
                  _c("img", { attrs: { src: _vm.imgfile, alt: "" } })
                ])
              ])
            ])
          ]),
          _c(
            "div",
            { staticClass: "collapseWrap" },
            [
              _c(
                "el-collapse",
                {
                  model: {
                    value: _vm.activeNames,
                    callback: function($$v) {
                      _vm.activeNames = $$v
                    },
                    expression: "activeNames"
                  }
                },
                [
                  _vm.warningFormType === "people"
                    ? _c(
                        "div",
                        { staticClass: "peopleFromWrap" },
                        [
                          _c(
                            "el-collapse-item",
                            { attrs: { title: "基本資料" } },
                            [
                              _c("div", { staticClass: "BasicInfoWrap" }, [
                                _c(
                                  "section",
                                  { staticClass: "nameInput" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "名稱-姓",
                                          prop: "Base.FirstName"
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "請輸入姓",
                                            disabled: _vm.disabled
                                          },
                                          model: {
                                            value: _vm.postForm.Base.FirstName,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.postForm.Base,
                                                "FirstName",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression:
                                              "postForm.Base.FirstName"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "名稱-中間名" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "請輸入中間名",
                                            disabled: _vm.disabled
                                          },
                                          model: {
                                            value: _vm.postForm.Base.MiddleName,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.postForm.Base,
                                                "MiddleName",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression:
                                              "postForm.Base.MiddleName"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "名稱-名" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "請輸入名",
                                            disabled: _vm.disabled
                                          },
                                          model: {
                                            value: _vm.postForm.Base.LastName,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.postForm.Base,
                                                "LastName",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "postForm.Base.LastName"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("section", { staticClass: "peopleSelect" }, [
                                  _c(
                                    "div",
                                    { staticClass: "peopleSelect--customer" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "peopleSelect--customer--content"
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop: "Base.IsCustomer",
                                                label: "是否為既有客戶"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder:
                                                      "是否為既有客戶",
                                                    disabled: _vm.disabled
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.postForm.Base
                                                        .IsCustomer,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.postForm.Base,
                                                        "IsCustomer",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "postForm.Base.IsCustomer"
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.customerSelectData,
                                                  function(item) {
                                                    return _c("el-option", {
                                                      key: item.value,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.value
                                                      }
                                                    })
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "peopleSelect--gender" },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "性別" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "請選擇性別",
                                                disabled: _vm.disabled
                                              },
                                              model: {
                                                value: _vm.postForm.Base.Gender,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.postForm.Base,
                                                    "Gender",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "postForm.Base.Gender"
                                              }
                                            },
                                            _vm._l(_vm.sexSelectData, function(
                                              item
                                            ) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "peopleSelect--attribute" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "名單屬性",
                                            prop: "Attribute"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                "value-key": "ID",
                                                id: "attribute",
                                                multiple: "",
                                                placeholder:
                                                  "請選擇性別名單屬性"
                                              },
                                              model: {
                                                value: _vm.postForm.Attribute,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.postForm,
                                                    "Attribute",
                                                    $$v
                                                  )
                                                },
                                                expression: "postForm.Attribute"
                                              }
                                            },
                                            _vm._l(_vm.AttrSelectData, function(
                                              item
                                            ) {
                                              return _c("el-option", {
                                                key: item,
                                                attrs: {
                                                  value: item,
                                                  label: item
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _c(
                                  "section",
                                  { staticClass: "idNum" },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "身分證字號:" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            disabled: _vm.disabled,
                                            placeholder: "請輸入身分證字號"
                                          },
                                          model: {
                                            value: _vm.postForm.Base.IDNo1,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.postForm.Base,
                                                "IDNo1",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "postForm.Base.IDNo1"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "護照號碼:" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            disabled: _vm.disabled,
                                            placeholder: "請輸入護照號碼"
                                          },
                                          model: {
                                            value: _vm.postForm.Base.IDNo2,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.postForm.Base,
                                                "IDNo2",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "postForm.Base.IDNo2"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "section",
                                  { staticClass: "birthdayAndBirthPlace" },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "出生地" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "請輸入出生地",
                                            disabled: _vm.disabled
                                          },
                                          model: {
                                            value: _vm.postForm.Base.BirthPlace,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.postForm.Base,
                                                "BirthPlace",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression:
                                              "postForm.Base.BirthPlace"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "birthWrap" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "itemTitle" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "gender" } },
                                              [_vm._v("生日")]
                                            )
                                          ]
                                        ),
                                        _c("el-date-picker", {
                                          attrs: {
                                            disabled: _vm.disabled,
                                            editable: false,
                                            "value-format": "yyyy-MM-dd",
                                            type: "date",
                                            placeholder: "請選擇生日"
                                          },
                                          model: {
                                            value: _vm.postForm.Base.Birthday,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.postForm.Base,
                                                "Birthday",
                                                $$v
                                              )
                                            },
                                            expression: "postForm.Base.Birthday"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "section",
                                  { staticClass: "nation" },
                                  _vm._l(_vm.postForm.Nation, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "loopWrap" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "國籍" + (index + 1)
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                placeholder:
                                                  "請輸入國籍" + (index + 1)
                                              },
                                              model: {
                                                value: item.Nation,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "Nation",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression: "item.Nation"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        index == 0
                                          ? _c("el-button", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                type: "primary",
                                                icon: "el-icon-plus"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addMultipleInput(
                                                    "Nation"
                                                  )
                                                }
                                              }
                                            })
                                          : _c("el-button", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                type: "danger",
                                                icon: "el-icon-delete"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteMultipleInput(
                                                    index,
                                                    "Nation"
                                                  )
                                                }
                                              }
                                            })
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              ])
                            ]
                          ),
                          _c(
                            "el-collapse-item",
                            { attrs: { title: "其他別名" } },
                            [
                              _c("div", { staticClass: "otherNameWrap" }, [
                                _c(
                                  "div",
                                  { staticClass: "multipleWrap" },
                                  _vm._l(_vm.postForm.TransName, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "loopWrap" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "翻譯名" + (index + 1)
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                placeholder:
                                                  "請輸入翻譯名" + (index + 1)
                                              },
                                              model: {
                                                value: item.TransName,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "TransName",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression: "item.TransName"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        index == 0
                                          ? _c("el-button", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                type: "primary",
                                                icon: "el-icon-plus"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addMultipleInput(
                                                    "TransName"
                                                  )
                                                }
                                              }
                                            })
                                          : _c("el-button", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                type: "danger",
                                                icon: "el-icon-delete"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteMultipleInput(
                                                    index,
                                                    "TransName"
                                                  )
                                                }
                                              }
                                            })
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                ),
                                _c(
                                  "div",
                                  { staticClass: "multipleWrap" },
                                  _vm._l(_vm.postForm.OtherName, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "loopWrap" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "其他稱呼" + (index + 1)
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                placeholder:
                                                  "請輸入其他稱呼" + (index + 1)
                                              },
                                              model: {
                                                value: item.OtherName,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "OtherName",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression: "item.OtherName"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        index == 0
                                          ? _c("el-button", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                type: "primary",
                                                icon: "el-icon-plus"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addMultipleInput(
                                                    "OtherName"
                                                  )
                                                }
                                              }
                                            })
                                          : _c("el-button", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                type: "danger",
                                                icon: "el-icon-delete"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteMultipleInput(
                                                    index,
                                                    "OtherName"
                                                  )
                                                }
                                              }
                                            })
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              ])
                            ]
                          ),
                          _c(
                            "el-collapse-item",
                            { attrs: { title: "就職紀錄" } },
                            [
                              _c(
                                "div",
                                { staticClass: "workWrap" },
                                _vm._l(_vm.postForm.Work, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "loopWrap" },
                                    [
                                      _c("div", [
                                        _c(
                                          "div",
                                          { staticClass: "inputContent" },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "職業" + (index + 1)
                                                }
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled: _vm.disabled,
                                                    placeholder:
                                                      "請輸入職業" + (index + 1)
                                                  },
                                                  model: {
                                                    value: item.Occupation,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "Occupation",
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.Occupation"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "職稱" + (index + 1)
                                                }
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled: _vm.disabled,
                                                    placeholder:
                                                      "請輸入職稱" + (index + 1)
                                                  },
                                                  model: {
                                                    value: item.Title,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "Title",
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression: "item.Title"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "dateContent" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "dateWrap" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "itemTitle" },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: { for: "gender" }
                                                      },
                                                      [_vm._v("就職日")]
                                                    )
                                                  ]
                                                ),
                                                _c("el-date-picker", {
                                                  attrs: {
                                                    type: "date",
                                                    editable: false,
                                                    placeholder: "請選擇就職日",
                                                    "value-format":
                                                      "yyyy-MM-dd",
                                                    disabled: _vm.disabled
                                                  },
                                                  model: {
                                                    value: item.ArrivedDt,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "ArrivedDt",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.ArrivedDt"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "dateWrap" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "itemTitle" },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: { for: "gender" }
                                                      },
                                                      [_vm._v("離職日")]
                                                    )
                                                  ]
                                                ),
                                                _c("el-date-picker", {
                                                  attrs: {
                                                    type: "date",
                                                    editable: false,
                                                    placeholder: "請選擇離職日",
                                                    "value-format":
                                                      "yyyy-MM-dd",
                                                    disabled: _vm.disabled
                                                  },
                                                  model: {
                                                    value: item.LeavedDt,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "LeavedDt",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.LeavedDt"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]),
                                      index == 0
                                        ? _c("el-button", {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              type: "primary",
                                              icon: "el-icon-plus"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.addMultipleInput(
                                                  "Work"
                                                )
                                              }
                                            }
                                          })
                                        : _c("el-button", {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              type: "danger",
                                              icon: "el-icon-delete"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteMultipleInput(
                                                  index,
                                                  "Work"
                                                )
                                              }
                                            }
                                          })
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ]
                          ),
                          _c(
                            "el-collapse-item",
                            { attrs: { title: "聯絡資訊" } },
                            [
                              _c("div", { staticClass: "contact" }, [
                                _c(
                                  "div",
                                  { staticClass: "multipleWrap" },
                                  _vm._l(_vm.postForm.Contact, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "loopWrap" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "聯絡方式" + (index + 1)
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                placeholder:
                                                  "請輸入聯絡方式" + (index + 1)
                                              },
                                              model: {
                                                value: item.Contact,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "Contact",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression: "item.Contact"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        index == 0
                                          ? _c("el-button", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                type: "primary",
                                                icon: "el-icon-plus"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addMultipleInput(
                                                    "Contact"
                                                  )
                                                }
                                              }
                                            })
                                          : _c("el-button", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                type: "danger",
                                                icon: "el-icon-delete"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteMultipleInput(
                                                    index,
                                                    "Contact"
                                                  )
                                                }
                                              }
                                            })
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                ),
                                _c(
                                  "div",
                                  { staticClass: "multipleWrap" },
                                  _vm._l(_vm.postForm.Address, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "loopWrap" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "居住地" + (index + 1)
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                placeholder:
                                                  "請輸入居住地" + (index + 1)
                                              },
                                              model: {
                                                value: item.Place,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "Place",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression: "item.Place"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        index == 0
                                          ? _c("el-button", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                type: "primary",
                                                icon: "el-icon-plus"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addMultipleInput(
                                                    "Address"
                                                  )
                                                }
                                              }
                                            })
                                          : _c("el-button", {
                                              attrs: {
                                                type: "danger",
                                                disabled: _vm.disabled,
                                                icon: "el-icon-delete"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteMultipleInput(
                                                    index,
                                                    "Address"
                                                  )
                                                }
                                              }
                                            })
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              ])
                            ]
                          ),
                          _c(
                            "el-collapse-item",
                            { attrs: { title: "關聯資訊" } },
                            [
                              _c(
                                "div",
                                { staticClass: "relationInfo" },
                                _vm._l(_vm.postForm.Relation, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "loopWrap" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "inputContent" },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "關聯名稱" + (index + 1)
                                              }
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                  placeholder:
                                                    "請輸入關聯名稱" +
                                                    (index + 1)
                                                },
                                                model: {
                                                  value: item.RelEntity,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "RelEntity",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression: "item.RelEntity"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "關聯關係" + (index + 1)
                                              }
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                  placeholder:
                                                    "請輸入關聯關係" +
                                                    (index + 1)
                                                },
                                                model: {
                                                  value: item.RelMemo,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "RelMemo",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression: "item.RelMemo"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          index == 0
                                            ? _c("el-button", {
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                  type: "primary",
                                                  icon: "el-icon-plus"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.addMultipleInput(
                                                      "Relation"
                                                    )
                                                  }
                                                }
                                              })
                                            : _c("el-button", {
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                  type: "danger",
                                                  icon: "el-icon-delete"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteMultipleInput(
                                                      index,
                                                      "Relation"
                                                    )
                                                  }
                                                }
                                              })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ]
                          ),
                          _c(
                            "el-collapse-item",
                            { attrs: { title: "備註說明", name: "3" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  disabled: _vm.disabled,
                                  autosize: { minRows: 2, maxRows: 4 },
                                  placeholder: "請輸入内容"
                                },
                                model: {
                                  value: _vm.postForm.Base.Memo,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.postForm.Base,
                                      "Memo",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "postForm.Base.Memo"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.warningFormType === "org"
                    ? _c(
                        "div",
                        { staticClass: "orgFromWrap" },
                        [
                          _c(
                            "el-collapse-item",
                            { attrs: { title: "基本資料", name: "2" } },
                            [
                              _c("div", [
                                _c("div", { staticClass: "BasicInfoWrap" }, [
                                  _c(
                                    "section",
                                    { staticClass: "nameInput" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "公司中文名稱：",
                                            prop: "Base.ComCnName"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              placeholder: "請輸入公司中文名稱"
                                            },
                                            model: {
                                              value:
                                                _vm.postForm.Base.ComCnName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.postForm.Base,
                                                  "ComCnName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "postForm.Base.ComCnName"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "公司英文名稱：" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              placeholder: "請輸入公司英文名稱"
                                            },
                                            model: {
                                              value:
                                                _vm.postForm.Base.ComEngName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.postForm.Base,
                                                  "ComEngName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "postForm.Base.ComEngName"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "代表人：" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              placeholder: "請輸入代表人"
                                            },
                                            model: {
                                              value: _vm.postForm.Base.RepName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.postForm.Base,
                                                  "RepName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "postForm.Base.RepName"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("section", { staticClass: "orgSelect" }, [
                                    _c(
                                      "div",
                                      { staticClass: "orgSelect--customer" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "orgSelect--customer--content"
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop: "Base.IsCustomer",
                                                  label: "是否為既有客戶："
                                                }
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      placeholder:
                                                        "是否為既有客戶",
                                                      disabled: _vm.disabled
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.postForm.Base
                                                          .IsCustomer,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.postForm.Base,
                                                          "IsCustomer",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "postForm.Base.IsCustomer"
                                                    }
                                                  },
                                                  _vm._l(
                                                    _vm.customerSelectData,
                                                    function(item) {
                                                      return _c("el-option", {
                                                        key: item.value,
                                                        attrs: {
                                                          label: item.label,
                                                          value: item.value
                                                        }
                                                      })
                                                    }
                                                  ),
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "orgSelect--attribute" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "名單屬性：",
                                              prop: "Attribute"
                                            }
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                  "value-key": "ID",
                                                  id: "attribute",
                                                  multiple: "",
                                                  placeholder:
                                                    "請選擇性別名單屬性"
                                                },
                                                model: {
                                                  value: _vm.postForm.Attribute,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.postForm,
                                                      "Attribute",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "postForm.Attribute"
                                                }
                                              },
                                              _vm._l(
                                                _vm.AttrSelectData,
                                                function(item) {
                                                  return _c("el-option", {
                                                    key: item,
                                                    attrs: {
                                                      value: item,
                                                      label: item
                                                    }
                                                  })
                                                }
                                              ),
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c(
                                    "section",
                                    { staticClass: "uniComNmAndApvDt" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "統一編號：",
                                            prop: "Base.UniComNm"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              placeholder: "請輸入統一編號"
                                            },
                                            model: {
                                              value: _vm.postForm.Base.UniComNm,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.postForm.Base,
                                                  "UniComNm",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "postForm.Base.UniComNm"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "apvDtWrap" },
                                        [
                                          _c("p", { staticClass: "subtitle" }, [
                                            _c(
                                              "label",
                                              { attrs: { for: "gender" } },
                                              [_vm._v("設立日期：")]
                                            )
                                          ]),
                                          _c("el-date-picker", {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              editable: false,
                                              "value-format": "yyyy-MM-dd",
                                              type: "date",
                                              placeholder: "請選擇設立日期"
                                            },
                                            model: {
                                              value: _vm.postForm.Base.ApvDt,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.postForm.Base,
                                                  "ApvDt",
                                                  $$v
                                                )
                                              },
                                              expression: "postForm.Base.ApvDt"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "註冊地：" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "請輸入註冊地",
                                              disabled: _vm.disabled
                                            },
                                            model: {
                                              value:
                                                _vm.postForm.Base.BirthPlace,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.postForm.Base,
                                                  "BirthPlace",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "postForm.Base.BirthPlace"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "section",
                                    { staticClass: "nationAndIndus" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "multipleWrap" },
                                        _vm._l(_vm.postForm.OrgIndus, function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "loopWrap"
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label:
                                                      "經營產業" + (index + 1)
                                                  }
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      disabled: _vm.disabled,
                                                      placeholder:
                                                        "請輸入經營產業" +
                                                        (index + 1)
                                                    },
                                                    model: {
                                                      value: item.Indus,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "Indus",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression: "item.Indus"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              index == 0
                                                ? _c("el-button", {
                                                    attrs: {
                                                      disabled: _vm.disabled,
                                                      type: "primary",
                                                      icon: "el-icon-plus"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.addMultipleInput(
                                                          "OrgIndus"
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _c("el-button", {
                                                    attrs: {
                                                      disabled: _vm.disabled,
                                                      type: "danger",
                                                      icon: "el-icon-delete"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteMultipleInput(
                                                          index,
                                                          "OrgIndus"
                                                        )
                                                      }
                                                    }
                                                  })
                                            ],
                                            1
                                          )
                                        }),
                                        0
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "multipleWrap" },
                                        _vm._l(_vm.postForm.Nation, function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "loopWrap"
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "國籍" + (index + 1)
                                                  }
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      disabled: _vm.disabled,
                                                      placeholder:
                                                        "請輸入國籍" +
                                                        (index + 1)
                                                    },
                                                    model: {
                                                      value: item.Nation,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "Nation",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression: "item.Nation"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              index == 0
                                                ? _c("el-button", {
                                                    attrs: {
                                                      disabled: _vm.disabled,
                                                      type: "primary",
                                                      icon: "el-icon-plus"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.addMultipleInput(
                                                          "Nation"
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _c("el-button", {
                                                    attrs: {
                                                      disabled: _vm.disabled,
                                                      type: "danger",
                                                      icon: "el-icon-delete"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteMultipleInput(
                                                          index,
                                                          "Nation"
                                                        )
                                                      }
                                                    }
                                                  })
                                            ],
                                            1
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ]
                          ),
                          _c(
                            "el-collapse-item",
                            { attrs: { title: "其他別名" } },
                            [
                              _c("div", { staticClass: "otherNameWrap" }, [
                                _c(
                                  "div",
                                  { staticClass: "multipleWrap" },
                                  _vm._l(_vm.postForm.TransName, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "loopWrap" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label:
                                                "其他翻譯名稱" + (index + 1)
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                placeholder:
                                                  "請輸入其他翻譯名稱" +
                                                  (index + 1)
                                              },
                                              model: {
                                                value: item.TransName,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "TransName",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression: "item.TransName"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        index == 0
                                          ? _c("el-button", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                type: "primary",
                                                icon: "el-icon-plus"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addMultipleInput(
                                                    "TransName"
                                                  )
                                                }
                                              }
                                            })
                                          : _c("el-button", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                type: "danger",
                                                icon: "el-icon-delete"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteMultipleInput(
                                                    index,
                                                    "TransName"
                                                  )
                                                }
                                              }
                                            })
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                ),
                                _c(
                                  "div",
                                  { staticClass: "multipleWrap" },
                                  _vm._l(_vm.postForm.OtherName, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "loopWrap" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "公司簡稱" + (index + 1)
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                placeholder:
                                                  "請輸入公司簡稱" + (index + 1)
                                              },
                                              model: {
                                                value: item.OtherName,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "OtherName",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression: "item.OtherName"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        index == 0
                                          ? _c("el-button", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                type: "primary",
                                                icon: "el-icon-plus"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addMultipleInput(
                                                    "OtherName"
                                                  )
                                                }
                                              }
                                            })
                                          : _c("el-button", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                type: "danger",
                                                icon: "el-icon-delete"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteMultipleInput(
                                                    index,
                                                    "OtherName"
                                                  )
                                                }
                                              }
                                            })
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              ])
                            ]
                          ),
                          _c(
                            "el-collapse-item",
                            { attrs: { title: "變更紀錄" } },
                            [
                              _c(
                                "div",
                                { staticClass: "OrgRecordWrap" },
                                _vm._l(_vm.postForm.OrgRecord, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "loopWrap" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "content" },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label:
                                                  "公司變更/停業/結束項目" +
                                                  (index + 1)
                                              }
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                  placeholder:
                                                    "請輸入公司變更/停業/結束項目" +
                                                    (index + 1)
                                                },
                                                model: {
                                                  value: item.ChangeItem,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "ChangeItem",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression: "item.ChangeItem"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "dateWrap" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "itemTitle" },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      "公司變更/停業/結束日期"
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c("el-date-picker", {
                                                attrs: {
                                                  type: "date",
                                                  editable: false,
                                                  placeholder:
                                                    "公司變更/停業/結束日期",
                                                  "value-format": "yyyy-MM-dd",
                                                  disabled: _vm.disabled
                                                },
                                                model: {
                                                  value: item.ChangeDt,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "ChangeDt",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.ChangeDt"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      index == 0
                                        ? _c("el-button", {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              type: "primary",
                                              icon: "el-icon-plus"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.addMultipleInput(
                                                  "OrgRecord"
                                                )
                                              }
                                            }
                                          })
                                        : _c("el-button", {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              type: "danger",
                                              icon: "el-icon-delete"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteMultipleInput(
                                                  index,
                                                  "OrgRecord"
                                                )
                                              }
                                            }
                                          })
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ]
                          ),
                          _c(
                            "el-collapse-item",
                            { attrs: { title: "聯絡資訊" } },
                            [
                              _c("div", { staticClass: "contact" }, [
                                _c(
                                  "div",
                                  { staticClass: "multipleWrap" },
                                  _vm._l(_vm.postForm.Contact, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "loopWrap" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "聯絡方式" + (index + 1)
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                placeholder:
                                                  "聯絡方式" + (index + 1)
                                              },
                                              model: {
                                                value: item.Contact,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "Contact",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression: "item.Contact"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        index == 0
                                          ? _c("el-button", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                type: "primary",
                                                icon: "el-icon-plus"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addMultipleInput(
                                                    "Contact"
                                                  )
                                                }
                                              }
                                            })
                                          : _c("el-button", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                type: "danger",
                                                icon: "el-icon-delete"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteMultipleInput(
                                                    index,
                                                    "Contact"
                                                  )
                                                }
                                              }
                                            })
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                ),
                                _c(
                                  "div",
                                  { staticClass: "multipleWrap" },
                                  _vm._l(_vm.postForm.Address, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "loopWrap" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "營運地" + (index + 1)
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                placeholder:
                                                  "營運地" + (index + 1)
                                              },
                                              model: {
                                                value: item.Place,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "Place",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression: "item.Place"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        index == 0
                                          ? _c("el-button", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                type: "primary",
                                                icon: "el-icon-plus"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addMultipleInput(
                                                    "Address"
                                                  )
                                                }
                                              }
                                            })
                                          : _c("el-button", {
                                              attrs: {
                                                type: "danger",
                                                disabled: _vm.disabled,
                                                icon: "el-icon-delete"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteMultipleInput(
                                                    index,
                                                    "Address"
                                                  )
                                                }
                                              }
                                            })
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              ])
                            ]
                          ),
                          _c(
                            "el-collapse-item",
                            { attrs: { title: "關聯資訊" } },
                            [
                              _c(
                                "div",
                                { staticClass: "relationInfo" },
                                _vm._l(_vm.postForm.Relation, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "loopWrap" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "content" },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "關聯公司" + (index + 1)
                                              }
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                  placeholder:
                                                    "關聯公司" + (index + 1)
                                                },
                                                model: {
                                                  value: item.RelEntity,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "RelEntity",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression: "item.RelEntity"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "關聯關係" + (index + 1)
                                              }
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                  placeholder:
                                                    "關聯關係" + (index + 1)
                                                },
                                                model: {
                                                  value: item.RelMemo,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "RelMemo",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression: "item.RelMemo"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      index == 0
                                        ? _c("el-button", {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              type: "primary",
                                              icon: "el-icon-plus"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.addMultipleInput(
                                                  "Relation"
                                                )
                                              }
                                            }
                                          })
                                        : _c("el-button", {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              type: "danger",
                                              icon: "el-icon-delete"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteMultipleInput(
                                                  index,
                                                  "Relation"
                                                )
                                              }
                                            }
                                          })
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ]
                          ),
                          _c(
                            "el-collapse-item",
                            { attrs: { title: "備註說明", name: "3" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  disabled: _vm.disabled,
                                  autosize: { minRows: 2, maxRows: 4 },
                                  placeholder: "請輸入内容"
                                },
                                model: {
                                  value: _vm.postForm.Base.Memo,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.postForm.Base,
                                      "Memo",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "postForm.Base.Memo"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }