var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "WarningFormBtn" }, [
    _vm.btnStatus === "saveAndWaitApproval"
      ? _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "large" },
                on: {
                  click: function($event) {
                    return _vm.doFunction("back")
                  }
                }
              },
              [_vm._v("取消")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "large" },
                on: {
                  click: function($event) {
                    return _vm.doFunction("save")
                  }
                }
              },
              [_vm._v("儲存")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "large" },
                on: {
                  click: function($event) {
                    return _vm.doFunction("waitApproval")
                  }
                }
              },
              [_vm._v("送出")]
            )
          ],
          1
        )
      : _vm.btnStatus === "approvalAndReject"
      ? _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "large" },
                on: {
                  click: function($event) {
                    return _vm.doFunction("approval")
                  }
                }
              },
              [_vm._v("核准")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "large" },
                on: {
                  click: function($event) {
                    return _vm.doFunction("reject")
                  }
                }
              },
              [_vm._v("退回")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "large" },
                on: {
                  click: function($event) {
                    return _vm.doFunction("back")
                  }
                }
              },
              [_vm._v("取消")]
            )
          ],
          1
        )
      : _vm.btnStatus === "approvalDeleteAndReject"
      ? _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "large" },
                on: {
                  click: function($event) {
                    return _vm.doFunction("delete")
                  }
                }
              },
              [_vm._v("核准刪除")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "large" },
                on: {
                  click: function($event) {
                    return _vm.doFunction("reject")
                  }
                }
              },
              [_vm._v("退回")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "large" },
                on: {
                  click: function($event) {
                    return _vm.doFunction("back")
                  }
                }
              },
              [_vm._v("取消")]
            )
          ],
          1
        )
      : _vm.btnStatus === "cancel"
      ? _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "large" },
                on: {
                  click: function($event) {
                    return _vm.doFunction("back")
                  }
                }
              },
              [_vm._v("取消")]
            )
          ],
          1
        )
      : _vm.btnStatus === "cancelAndSave"
      ? _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "large" },
                on: {
                  click: function($event) {
                    return _vm.doFunction("addRelateInfo")
                  }
                }
              },
              [_vm._v("加入關聯資訊")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "large" },
                on: {
                  click: function($event) {
                    return _vm.doFunction("back")
                  }
                }
              },
              [_vm._v("取消")]
            )
          ],
          1
        )
      : _vm.btnStatus === "cancelAndRevise"
      ? _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "large" },
                on: {
                  click: function($event) {
                    return _vm.doFunction("revise")
                  }
                }
              },
              [_vm._v("修改")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "large" },
                on: {
                  click: function($event) {
                    return _vm.doFunction("back")
                  }
                }
              },
              [_vm._v("取消")]
            )
          ],
          1
        )
      : _vm.btnStatus === "reviseAndDelete"
      ? _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "large" },
                on: {
                  click: function($event) {
                    return _vm.doFunction("revise")
                  }
                }
              },
              [_vm._v("修改")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "large" },
                on: {
                  click: function($event) {
                    return _vm.doFunction("waitDelete")
                  }
                }
              },
              [_vm._v("刪除")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "large" },
                on: {
                  click: function($event) {
                    return _vm.doFunction("back")
                  }
                }
              },
              [_vm._v("取消")]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }