var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tabNews" },
    [
      _c(
        "div",
        { staticClass: "tabsWrap" },
        [
          _c("Tabs", {
            attrs: { typeNewsProp: _vm.typeNews },
            on: { handleChangeTab: _vm.changeTab }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", disabled: _vm.disabled },
              on: { click: _vm.toggleDialog }
            },
            [
              _c(
                "span",
                { staticStyle: { color: "white", "vertical-align": "middle" } },
                [
                  _c("i", { staticClass: "el-icon-search" }),
                  _vm._v("搜尋關聯資訊")
                ]
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "newsWrap" },
        [
          _c("el-transfer", {
            staticClass: "transfer",
            attrs: {
              titles: _vm.transfer[_vm.typeNews],
              data: _vm.leftTransferCheckbox
            },
            on: { change: _vm.transferChange },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var option = ref.option
                  return _c(
                    "span",
                    {
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.transferDetail(option)
                        }
                      }
                    },
                    [_vm._v(_vm._s(option.label))]
                  )
                }
              }
            ]),
            model: {
              value: _vm.candidateNewsID[_vm.typeNews],
              callback: function($$v) {
                _vm.$set(_vm.candidateNewsID, _vm.typeNews, $$v)
              },
              expression: "candidateNewsID[typeNews]"
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "searchRelationModal",
          attrs: {
            visible: _vm.dialogStatus,
            width: "80%",
            center: "",
            fullscreen: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogStatus = $event
            },
            close: _vm.resetDialog
          }
        },
        [
          _c(
            "div",
            { staticClass: "searchWrap" },
            [
              _c("el-date-picker", {
                attrs: {
                  editable: false,
                  "range-separator": "至",
                  "start-placeholder": "開始日期",
                  "end-placeholder": "結束日期",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd"
                },
                model: {
                  value: _vm.rangeDate,
                  callback: function($$v) {
                    _vm.rangeDate = $$v
                  },
                  expression: "rangeDate"
                }
              }),
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: {
                  clearable: "",
                  placeholder: "關鍵字搜尋",
                  type: "text"
                },
                model: {
                  value: _vm.listQuery.SearchContent,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.listQuery,
                      "SearchContent",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "listQuery.SearchContent"
                }
              }),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-search", type: "primary" },
                  on: { click: _vm.doSearch }
                },
                [_vm._v("搜尋")]
              )
            ],
            1
          ),
          _vm.typeNews === "searchNews"
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: { "max-height": "500", data: _vm.tableData },
                  on: { select: _vm.getSelectedRow }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "60" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "order", label: "序號", width: "60" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "NewsTitle",
                      label: "新聞標題",
                      width: "400"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "a",
                                {
                                  staticClass: "goDetailPage",
                                  on: {
                                    click: function($event) {
                                      return _vm.previewDetailPage(
                                        scope.row,
                                        "detailNews"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(scope.row.NewsTitle))]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1031227391
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "NewsTime", label: "新聞時間", width: "300" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "NewsSite", label: "新聞網站" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "NewsChannel", label: "新聞頻道" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "Sentiment",
                      label: "情緒指標",
                      width: "120"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.typeNews === "searChsan"
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: { "max-height": "500", data: _vm.tableData },
                  on: { select: _vm.getSelectedRow }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "30", fixed: "" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "SourceName", label: "來源名稱" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "ListType", label: "名單分類" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "ListID", label: "名單編號" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "a",
                                {
                                  staticClass: "goDetailPage",
                                  on: {
                                    click: function($event) {
                                      return _vm.previewDetailPage(
                                        scope.row,
                                        "tableDetailNews",
                                        "searChsan"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(scope.row.ListID))]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1467678079
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "UNListType", label: "聯合國名單種類" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "PublicDt", label: "發布時間" }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.typeNews === "searchConstr"
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: { "max-height": "700", data: _vm.tableData },
                  on: { select: _vm.getSelectedRow }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "50", fixed: "" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "CaseName",
                      label: "標案名稱",
                      width: "100"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "a",
                                {
                                  staticClass: "goDetailPage",
                                  on: {
                                    click: function($event) {
                                      return _vm.previewDetailPage(
                                        scope.row,
                                        "tableDetailNews",
                                        "searchConstr"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(scope.row.CaseName))]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3023862696
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "UniComNm", label: "廠商代碼", width: "100" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "CaseNm", label: "標案案號", width: "120" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "ComName", label: "廠商名稱", width: "100" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "ComLoc", label: "廠商地址", width: "100" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "ComCountry",
                      label: "廠商國別",
                      width: "100"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "PublicAuthNm",
                      label: "刊登機關代碼",
                      width: "100"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "PublicAuthName",
                      label: "刊登機關名稱",
                      width: "100"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "AuthLoc", label: "機關地址", width: "100" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "ContactPerson",
                      label: "聯絡人",
                      width: "100"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "ContactPhone",
                      label: "聯絡電話",
                      width: "100"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "GPActStatus",
                      label: "符合政府採購法第101條所列之情形",
                      width: "400"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "OrigPublicDt",
                      label: "原始公告日期",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "PublicDt", label: "公告日期", width: "120" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "EffectDt",
                      label: "拒絕往來生效日",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "GPActPeriod",
                      label: "政府採購法第103條第一項所規定之期間"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "DeadlineDt",
                      label: "拒絕往來截止日",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "AppealResult",
                      label: "異議或申訴結果",
                      width: "400"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.typeNews === "searchLabor"
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: { "max-height": "500", data: _vm.tableData },
                  on: { select: _vm.getSelectedRow }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "50", fixed: "" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "UnitEntity",
                      label: "事業單位名稱",
                      width: "180"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "a",
                                {
                                  staticClass: "goDetailPage",
                                  on: {
                                    click: function($event) {
                                      return _vm.previewDetailPage(
                                        scope.row,
                                        "tableDetailNews",
                                        "searchLabor"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(scope.row.UnitEntity))]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      738790851
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "MgrAuth", label: "主管機關", width: "120" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "PublicDt", label: "公告日期", width: "120" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "PunishDt", label: "處分日期" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "PunishNm", label: "處分字號", width: "180" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "NameEntity", label: "負責人 / 自然人姓名" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "VioLaw",
                      label: "違法法規法條",
                      width: "180"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "VioCont",
                      label: "違反法規內容",
                      width: "350"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "FineAmount", label: "罰鍰金額" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "Memo", label: "備註說明", width: "350" }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.typeNews === "searchEnviroment"
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: { "max-height": "500", data: _vm.tableData },
                  on: { select: _vm.getSelectedRow }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "50", fixed: "" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "CaseName",
                      label: "案件名稱",
                      width: "170"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "a",
                                {
                                  staticClass: "goDetailPage",
                                  on: {
                                    click: function($event) {
                                      return _vm.previewDetailPage(
                                        scope.row,
                                        "tableDetailNews",
                                        "searchEnviroment"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(scope.row.CaseName))]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3319124548
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "UnitName",
                      label: "行為人名稱",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "PunishTime",
                      label: "違反處分時間",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "VioFact", label: "違反事實", width: "180" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "VioLaw", label: "違反法令", width: "300" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "FineAmount",
                      label: "裁處金額-元",
                      width: "80"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "IsAppeal", label: "是否訴願", width: "50" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "AppealResult",
                      label: "訴願結果",
                      width: "180"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "RestrictTime",
                      label: "限制時間",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "IsImprove",
                      label: "是否已改善",
                      width: "180"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c("Pagination", {
            attrs: {
              pageSize: this.listQuery.Page_Size,
              propsCurrentPage: this.listQuery.Page_Num,
              totalLists: _vm.totalLists
            },
            on: { currentChange: _vm.pageChange }
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.AddNewsToCandidate }
                },
                [_vm._v("加入")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning", icon: "el-icon-close" },
                  on: { click: _vm.toggleDialog }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }