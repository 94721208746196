import { render, staticRenderFns } from "./ProfileForm.vue?vue&type=template&id=12787d8d&scoped=true&"
import script from "./ProfileForm.vue?vue&type=script&lang=js&"
export * from "./ProfileForm.vue?vue&type=script&lang=js&"
import style0 from "./ProfileForm.vue?vue&type=style&index=0&id=12787d8d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12787d8d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/kennyliao/Desktop/Peter/BIA/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('12787d8d')) {
      api.createRecord('12787d8d', component.options)
    } else {
      api.reload('12787d8d', component.options)
    }
    module.hot.accept("./ProfileForm.vue?vue&type=template&id=12787d8d&scoped=true&", function () {
      api.rerender('12787d8d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ProfileForm.vue"
export default component.exports