var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "uploadAttachment" },
    [
      !_vm.disabled
        ? _c("div", { staticClass: "filesLimit" }, [
            _c("span", [_vm._v("上傳檔案請勿超過20MB")])
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "addUploadWrap" },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                icon: "el-icon-plus",
                disabled: _vm.disabled
              },
              on: { click: _vm.addInputFile }
            },
            [_vm._v("新增上傳欄位")]
          )
        ],
        1
      ),
      _c(
        "el-form",
        { attrs: { model: _vm.files } },
        _vm._l(_vm.files.Attachement, function(eachFile, index) {
          return _c("section", { key: index, staticClass: "loopWrap" }, [
            _c("div", { staticClass: "uploadBtnWrap" }, [
              !_vm.disabled
                ? _c("div", [
                    _c(
                      "label",
                      { staticClass: "uploadStyle", attrs: { for: index } },
                      [
                        _c("span", [_vm._v("上傳")]),
                        _c("i", { staticClass: "el-icon-upload2" })
                      ]
                    ),
                    _c("input", {
                      staticClass: "inputBtn",
                      attrs: { id: index, type: "file" },
                      on: {
                        change: function($event) {
                          return _vm.recordFile($event, index, eachFile)
                        }
                      }
                    })
                  ])
                : _vm._e(),
              _vm.disabled && _vm.files.Attachement[0].AttUrl
                ? _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", icon: "el-icon-download" },
                          on: {
                            click: function($event) {
                              return _vm.downloadFiles(eachFile)
                            }
                          }
                        },
                        [_vm._v("下載")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _c(
              "div",
              { staticClass: "fileInfo" },
              [
                _c("p", { staticClass: "order" }, [
                  _vm._v("附件" + _vm._s(index + 1))
                ]),
                _c("el-input", {
                  attrs: { placeholder: "檔案名稱", disabled: true },
                  model: {
                    value: eachFile.AttName,
                    callback: function($$v) {
                      _vm.$set(
                        eachFile,
                        "AttName",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "eachFile.AttName"
                  }
                }),
                _c("el-input", {
                  attrs: { placeholder: "備註說明", disabled: _vm.disabled },
                  model: {
                    value: eachFile.Remark,
                    callback: function($$v) {
                      _vm.$set(
                        eachFile,
                        "Remark",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "eachFile.Remark"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              [
                _c("el-button", {
                  attrs: {
                    type: "danger",
                    icon: "el-icon-delete",
                    disabled: _vm.disabled
                  },
                  on: {
                    click: function($event) {
                      return _vm.delInputFile(index)
                    }
                  }
                })
              ],
              1
            )
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }