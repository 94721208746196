<template>
  <div class="tabNews">
    <!-- 所有新聞種類的標籤 -->
    <div class="tabsWrap">
      <Tabs :typeNewsProp="typeNews" @handleChangeTab="changeTab"></Tabs>
    </div>

    <div class="search">
      <el-button type="danger"  @click="toggleDialog" :disabled="disabled">
        <span style="color: white; vertical-align: middle"><i class="el-icon-search"></i>搜尋關聯資訊</span>
      </el-button>
    </div>

    <!-- 現有新聞＆候選新聞 -->
    <div class="newsWrap">
      <el-transfer class="transfer" :titles="transfer[typeNews]"
        v-model="candidateNewsID[typeNews]" 
        :data="leftTransferCheckbox" 
        @change="transferChange"
      >
        <span slot-scope="{ option }" @click.prevent="transferDetail(option)">{{ option.label }}</span>
      </el-transfer>
    </div>

    <!-- 新聞dialog -->
    <el-dialog class="searchRelationModal" :visible.sync="dialogStatus" width="80%" center fullscreen @close="resetDialog">
      <!-- 搜尋條件 -->
      <div class="searchWrap">
        <el-date-picker :editable="false" range-separator="至" start-placeholder="開始日期" end-placeholder="結束日期" type="daterange" value-format="yyyy-MM-dd"
          v-model="rangeDate"
        ></el-date-picker>
        <el-input v-model.trim="listQuery.SearchContent" clearable placeholder="關鍵字搜尋" type="text" style="width: 200px"></el-input>
        <el-button icon="el-icon-search" type="primary" @click="doSearch">搜尋</el-button>
      </div>

      <!-- 新聞表單 -->
        <!-- *負面新聞資料* -->
        <el-table v-if="typeNews==='searchNews'" v-loading="loading" style="width: 100%" ref="multipleTable" max-height="500"
          :data="tableData"
          @select="getSelectedRow"
        >
          <el-table-column type="selection" width="60"></el-table-column>
          <el-table-column prop="order" label="序號" width="60"></el-table-column>
          <el-table-column prop="NewsTitle" label="新聞標題" width="400">
            <template slot-scope="scope">
              <a class="goDetailPage" @click="previewDetailPage(scope.row,'detailNews')">{{scope.row.NewsTitle}}</a>
            </template>
          </el-table-column>
          <el-table-column prop="NewsTime" label="新聞時間" width="300"></el-table-column>
          <el-table-column prop="NewsSite" label="新聞網站"></el-table-column>
          <el-table-column prop="NewsChannel" label="新聞頻道"></el-table-column>
          <el-table-column prop="Sentiment" label="情緒指標" width="120"></el-table-column>
        </el-table>
        <!-- *聯合國制裁名單* -->
        <el-table v-if="typeNews==='searChsan'" style="width: 100%" ref="multipleTable" v-loading="loading" max-height="500"
          :data="tableData"
          @select="getSelectedRow"
        >
          <el-table-column type="selection" width="30" fixed> </el-table-column>
          <el-table-column prop="SourceName" label="來源名稱"></el-table-column>
          <el-table-column prop="ListType" label="名單分類"></el-table-column>
          <el-table-column prop="ListID" label="名單編號">
            <template slot-scope="scope">
              <a class="goDetailPage" @click="previewDetailPage(scope.row,'tableDetailNews','searChsan')">{{scope.row.ListID}}</a>
            </template>
          </el-table-column>
          <el-table-column prop="UNListType" label="聯合國名單種類"></el-table-column>
          <el-table-column prop="PublicDt" label="發布時間"></el-table-column>
        </el-table>
        <!-- *政府拒絕往來廠商名單* -->
        <el-table v-if="typeNews==='searchConstr'" style="width: 100%" ref="multipleTable" v-loading="loading" max-height="700"
          :data="tableData"
          @select="getSelectedRow"
        >
          <el-table-column type="selection" width="50" fixed></el-table-column>
          <el-table-column prop="CaseName" label="標案名稱" width="100">
            <template slot-scope="scope">
              <a class="goDetailPage" @click="previewDetailPage(scope.row,'tableDetailNews','searchConstr')">{{scope.row.CaseName}}</a>
            </template>
          </el-table-column>
          <el-table-column prop="UniComNm" label="廠商代碼" width="100"></el-table-column>
          <el-table-column prop="CaseNm" label="標案案號" width="120"></el-table-column>
          <el-table-column prop="ComName" label="廠商名稱" width="100"></el-table-column>
          <el-table-column prop="ComLoc" label="廠商地址" width="100"></el-table-column>
          <el-table-column prop="ComCountry" label="廠商國別" width="100"></el-table-column>
          <el-table-column prop="PublicAuthNm" label="刊登機關代碼" width="100"></el-table-column>
          <el-table-column prop="PublicAuthName" label="刊登機關名稱" width="100"></el-table-column>
          <el-table-column prop="AuthLoc" label="機關地址" width="100"></el-table-column>
          <el-table-column prop="ContactPerson" label="聯絡人" width="100"></el-table-column>
          <el-table-column prop="ContactPhone" label="聯絡電話" width="100"></el-table-column>
          <el-table-column prop="GPActStatus" label="符合政府採購法第101條所列之情形" width="400"></el-table-column>
          <el-table-column prop="OrigPublicDt" label="原始公告日期" width="120"></el-table-column>
          <el-table-column prop="PublicDt" label="公告日期" width="120"></el-table-column>
          <el-table-column prop="EffectDt" label="拒絕往來生效日" width="120"></el-table-column>
          <el-table-column prop="GPActPeriod" label="政府採購法第103條第一項所規定之期間"></el-table-column>
          <el-table-column prop="DeadlineDt" label="拒絕往來截止日" width="120"></el-table-column>
          <el-table-column prop="AppealResult" label="異議或申訴結果" width="400"></el-table-column>
        </el-table>
        <!-- *違反勞動法名單* -->
        <el-table v-if="typeNews==='searchLabor'" style="width: 100%" ref="multipleTable" v-loading="loading" max-height="500"
          :data="tableData"
          @select="getSelectedRow"
        >
          <el-table-column type="selection" width="50" fixed></el-table-column>
          <el-table-column prop="UnitEntity" label="事業單位名稱" width="180">
            <template slot-scope="scope">
              <a class="goDetailPage" @click="previewDetailPage(scope.row,'tableDetailNews','searchLabor')">{{scope.row.UnitEntity}}</a>
            </template>
          </el-table-column>
          <el-table-column prop="MgrAuth" label="主管機關" width="120"></el-table-column>
          <el-table-column prop="PublicDt" label="公告日期" width="120"></el-table-column>
          <el-table-column prop="PunishDt" label="處分日期"></el-table-column>
          <el-table-column prop="PunishNm" label="處分字號" width="180"></el-table-column>
          <el-table-column prop="NameEntity" label="負責人 / 自然人姓名"></el-table-column>
          <el-table-column prop="VioLaw" label="違法法規法條" width="180"></el-table-column>
          <el-table-column prop="VioCont" label="違反法規內容" width="350"></el-table-column>
          <el-table-column prop="FineAmount" label="罰鍰金額"></el-table-column>
          <el-table-column prop="Memo" label="備註說明" width="350"></el-table-column>
        </el-table>
        <!-- *環保署裁罰處分名單* -->
        <el-table v-if="typeNews==='searchEnviroment'" style="width: 100%" ref="multipleTable" v-loading="loading" max-height="500"
          :data="tableData"
          @select="getSelectedRow"
        >
          <el-table-column type="selection" width="50" fixed></el-table-column>
          <el-table-column prop="CaseName" label="案件名稱" width="170">
            <template slot-scope="scope">
              <a class="goDetailPage" @click="previewDetailPage(scope.row,'tableDetailNews','searchEnviroment')">{{scope.row.CaseName}}</a>
            </template>
          </el-table-column>
          <el-table-column prop="UnitName" label="行為人名稱" width="120"></el-table-column>
          <el-table-column prop="PunishTime" label="違反處分時間" width="120"></el-table-column>
          <el-table-column prop="VioFact" label="違反事實" width="180"></el-table-column>
          <el-table-column prop="VioLaw" label="違反法令" width="300"></el-table-column>
          <el-table-column prop="FineAmount" label="裁處金額-元" width="80"></el-table-column>
          <el-table-column prop="IsAppeal" label="是否訴願" width="50"></el-table-column>
          <el-table-column prop="AppealResult" label="訴願結果" width="180"></el-table-column>
          <el-table-column prop="RestrictTime" label="限制時間" width="120"></el-table-column>
          <el-table-column prop="IsImprove" label="是否已改善" width="180"></el-table-column>
        </el-table>
     
      <!-- pagination -->
      <Pagination
        @currentChange="pageChange"
        :pageSize="this.listQuery.Page_Size"
        :propsCurrentPage="this.listQuery.Page_Num"
        :totalLists="totalLists"
      ></Pagination>

      <!-- 按鈕 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="AddNewsToCandidate" type="primary" icon="el-icon-plus">加入</el-button>
        <el-button @click="toggleDialog" type="warning" icon="el-icon-close">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Tabs from "../components/Tabs.vue";
import Pagination from "../components/Pagination.vue";
export default {
  components: { Tabs, Pagination },
  props: {
    TabNewsFlag:{
      type: Boolean,
      default:false,
    },
    newsDataID:{
      type: Object,
    },
    status:{
      type: String,
    }
  },
  data() {
    return {
      loading:true,
      typeNews:"searchNews",
      // formQueryDataID:{},
      tableData: [],
      rangeDate:[],
      totalLists:null,
      dialogStatus: false,
      dialogInitialOpen:false,
      transfer:{
        searchNews:['現有關聯新聞-負面新聞','候選關聯新聞-負面新聞'],
        searChsan:['現有關聯新聞-聯合國制裁名單','候選關聯新聞-聯合國制裁名單'],
        searchConstr:['現有關聯新聞-政府拒絕往來名單','候選關聯新聞-政府拒絕往來名單'],
        searchLabor:['現有關聯新聞-違反勞動法名單','候選關聯新聞-違反勞動法名單'],
        searchEnviroment:['現有關聯新聞-環保署裁罰名單','候選關聯新聞-環保署裁罰名單']
      },
      listQuery: {
        Start_Date: '',
        End_Date: '',
        SearchContent:"",
        Page_Size: 10, //每頁有幾筆
        Page_Num: 1, //目前頁數
      },
      leftTransferCheckbox: [],//存放當前新聞種類已分類資料
      existingNewsID:{//現有新聞中,各新聞種類有的DataID,
        searchNews:[],
        searChsan:[],
        searchConstr:[],
        searchLabor:[],
        searchEnviroment:[]
      },
      candidateNewsID: {//候選新聞,v-model,事件觸發後值會變
        searchNews:[],
        searChsan:[],
        searchConstr:[],
        searchLabor:[],
        searchEnviroment:[]
      },
      multipleSelection:[],
      unClassifiedData:{
        searchNews:[],
        searChsan:[],
        searchConstr:[],
        searchLabor:[],
        searchEnviroment:[]
      },
      requestNews:{
        News: [//負面新聞
          // {
          //   ID:"",
          //   NewsID:"",//DataID
          //   NewsTitle:"",//新聞標題
          //   NewsTime:""//新聞日期(DateTime)
          // }
        ],
        Sanction: [//關聯聯合國制裁名單
          // {
          //   ID:"",
          //   SourceID:"",//DataID
          //   FirstName:"",//第一個名字
          //   SecondName:"",//第二個名字
          //   PublicDt:""//發布時間(Date)
          // }
        ],
        Constr: [//關聯政府拒絕名單
          // {
          //   ID:"",
          //   SourceID:"",//DataID
          //   ComName:"",//廠商名稱
          //   EffectDt:"",//拒絕往來生效日
          //   DeadlineDt:""//拒絕往來截止日(Date)
          // }
        ],
        Labor: [//關聯違反勞動法名單
          // {
          //   ID:"",
          //   SourceID:"",//DataID
          //   UnitEntity:"",//事業單位名稱
          //   VioLaw:"",//違法法規法條
          //   PunishDt:""//處分日期
          // }
        ],
        Enviroment: [//關聯環保署裁罰名單
          // {     
          //   ID:"",
          //   SourceID:"",//DataID
          //   UnitName:"",//行為人名稱
          //   PunishTime:"",//違反處分時間
          // }
        ],
      }
    };
  },
  watch:{
    TabNewsFlag(status){
      if(status){
        this.handleRequestNewsData()
      }
    },
    newsDataID:{
      handler(newsTypeDataID){
        let keys = Object.keys(newsTypeDataID);
        keys.forEach((key)=>{
          if(newsTypeDataID[key].length>0){
            //先存進現有的DataID
            switch(key){
              case 'News':
                this.existingNewsID.searchNews = JSON.parse(JSON.stringify(newsTypeDataID[key]))
                this.doGetNewsData('searchNews',newsTypeDataID[key])
                break
              case 'Constr':
                this.existingNewsID.searchConstr = JSON.parse(JSON.stringify(newsTypeDataID[key]))
                this.doGetNewsData('searchConstr',newsTypeDataID[key])
                break
              case 'Enviroment':
                this.existingNewsID.searchEnviroment = JSON.parse(JSON.stringify(newsTypeDataID[key]))
                this.doGetNewsData('searchEnviroment',newsTypeDataID[key])
                break
              case 'Labor':
                this.existingNewsID.searchLabor = JSON.parse(JSON.stringify(newsTypeDataID[key])) 
                this.doGetNewsData('searchLabor',newsTypeDataID[key])
                break
              case 'Sanction':
                this.existingNewsID.searChsan = JSON.parse(JSON.stringify(newsTypeDataID[key]))
                this.doGetNewsData('searChsan',newsTypeDataID[key])
                break
            }
          }
        })
        
      },
      immediate:true,
      deep:true,
    },
    disabled:{
      handler(newValue,oldValue){
        if(oldValue && !newValue){
          this.handleNewsDataClassification()
        }
      }
    }
  },
  computed:{
    disabled(){
      let result = null;
      if(this.status==='addStatus'||this.status==='editStatus'){
        result = false
        // this.handleNewsDataClassification();
      }else if(this.status==='previewStatus'){
        result = true
      }
      return result
    }
  },
  methods: {
    doSearch(){
      this.loading = true;
      this.listQuery.Page_Num = 1,
      this.listQuery.IsUser = true;
      // this.loadDialogLists()
      this.deleteNullValue();
      this.$api.data[this.typeNews](this.listQuery).then((res) => {
        this.tableData = [];
        this.totalLists = res.total;
        if(this.typeNews==='searChsan'){
          this.tableData = res.data.map((item)=>item.SanList)
        }else if(this.typeNews==='searchNews'){
          let order = 1;
          this.tableData = res.data;
          this.tableData.forEach((item) => {
            item.order = order;
            order++;
          });
        }else {
          this.tableData = res.data;
        }
        /*打完api後觸發check*/
        this.makeTableShowChecked();
        this.loading = false
      });
    },
    transferDetail(item){
      console.log(item);
      if(item.detailType==='news'){
        const route = this.$router.resolve({
        name:'detailNews',
          query: {
            DataID:item.key,
            typeNews:item.typeNews
          }
        })
        window.open(route.href,'_blank')
      }else if(item.detailType==='table'){
        const route = this.$router.resolve({
        name:'tableDetailNews',
          query: {
            DataID:item.key,
            typeNews:item.typeNews
          }
        })
        window.open(route.href,'_blank')
      }
    },
    previewDetailPage(item,typeDetail,typeNews){
      const route = this.$router.resolve({
        name:typeDetail,
        query: {
          DataID:item.DataID,
          typeNews:typeNews
        }
      })
      window.open(route.href,'_blank')
    },
    /*換頁數據變化時會觸發*/
    // selectionChange(selection){
    //   console.log("selectionChange",selection);
    //   this.multipleSelection = selection
    // },
    /*手動勾選數據Checkbox所觸發的event*/
    getSelectedRow(selection,row){
      let manageAllDataID = this.multipleSelection.map((item)=>item.DataID);
      let isIncludes = manageAllDataID.includes(row.DataID);
      if(isIncludes){
        // delete
        let index = manageAllDataID.indexOf(row.DataID)
        this.multipleSelection.splice(index,1)
      }else{
        //push
        this.multipleSelection.push(row)
      }
    },
    filterCandidateNews(){
      this.multipleSelection = 
        this.unClassifiedData[this.typeNews].filter((item)=>this.candidateNewsID[this.typeNews].includes(item.DataID)) 
    },
    handleRequestNewsData(){
      let requestData = JSON.parse(JSON.stringify(this.existingNewsID))
      /*處理成表單需要的格式*/
      let existingNewsKeys = Object.keys(requestData);
      existingNewsKeys.forEach((key)=>{
        requestData[key] = this.unClassifiedData[key].filter((item)=>requestData[key].includes(item.DataID))
        requestData[key] = requestData[key].map((item)=>(item.DataID));
      })
      this.$emit("handleNewsData",requestData)
    },
    /*穿梭匡箭頭click event*/
    transferChange(value,direction,movedKey){
      //從候選的DataID中篩出現有新聞的DataID
      let filterExistingDataID = this.leftTransferCheckbox.filter((item)=>{
        return !this.candidateNewsID[this.typeNews].includes(item.key)
      })
      this.existingNewsID[this.typeNews] = filterExistingDataID.map((item)=>item.key);
    },
    resetDialog(){
      this.listQuery.Page_Num = 1;
      this.rangeDate = [];
      this.listQuery.Start_Date = "";
      this.listQuery.End_Date = "";
      this.listQuery.SearchContent =""
      this.multipleSelection=[];
      this.$refs.multipleTable.clearSelection();
    },
    pageChange(val) {
      this.listQuery.Page_Num = val;
      this.loadDialogLists();
    },
    changeTab(typeNews) {
      this.typeNews = typeNews;
      this.handleNewsDataClassification();
    },
    deleteNullValue() {
      if(!!this.rangeDate){
        if(this.rangeDate.length !== 0){
          this.listQuery.Start_Date = this.rangeDate[0]
          this.listQuery.End_Date = this.rangeDate[1]
        }
      }else{
        this.listQuery.Start_Date = "";
        this.listQuery.End_Date = "";
      }
      let keys = Object.keys(this.listQuery)
      keys.forEach((key)=>{
        if(!this.listQuery[key]){
          delete this.listQuery[key]
        }
      })
    },
    isUserDelete(){
      let keys = Object.keys(this.listQuery);
      keys.forEach((key)=>{
        if(key==='IsUser'){
          delete this.listQuery[key]
        }
      })
    },
    loadDialogLists() {
        this.loading = true;
        this.isUserDelete()
        this.deleteNullValue();
        this.$api.data[this.typeNews](this.listQuery).then((res) => {
          this.tableData = [];
          this.totalLists = res.total;
          if(this.typeNews==='searChsan'){
            this.tableData = res.data.map((item)=>item.SanList)
          }else if(this.typeNews==='searchNews'){
            let order = 1;
            this.tableData = res.data;
            this.tableData.forEach((item) => {
              item.order = order;
              order++;
            });
          }else {
            this.tableData = res.data;
          }
          /*打完api後觸發check*/
          this.makeTableShowChecked();
          this.loading = false
        });
    },
    makeTableShowChecked(){
      this.$nextTick(function () {
        let currentTypeNewsDataID = this.multipleSelection.map((item)=>(item.DataID))
        this.tableData.forEach((item) => {
          if(currentTypeNewsDataID.includes(item.DataID)){
            this.$refs.multipleTable.toggleRowSelection(item, true);
          }
        })
      })
    },
    toggleDialog() {
      this.dialogStatus = !this.dialogStatus;
      if(this.dialogStatus){
        this.dialogInitialOpen = true;
        this.filterCandidateNews()
        this.loadDialogLists();
      }else{
        this.resetDialog()
      }
    },
    AddNewsToCandidate(){
      //multipleSelection先排除現有新聞
      if(this.multipleSelection.length>0){
        this.multipleSelection = this.multipleSelection.filter((item)=>!this.existingNewsID[this.typeNews].includes(item.DataID))
      }
      //unClassifiedData排除目前候選新聞
      if(this.unClassifiedData[this.typeNews].length>0){
        this.unClassifiedData[this.typeNews] = this.unClassifiedData[this.typeNews].filter((item)=>!this.candidateNewsID[this.typeNews].includes(item.DataID))
      }
      this.multipleSelection.forEach((item)=>{
        this.unClassifiedData[this.typeNews].push(item);
      })
      this.handleNewsDataClassification()
      this.toggleDialog();
    },
    handleNewsDataClassification(){
      console.log("分類新聞fn");
      this.produceExistingNews(this.typeNews);
      this.produceCandidateNews();
    },
    /*處理transfer的左邊(現有新聞)*/
    produceExistingNews(type) {
      this.leftTransferCheckbox = [];
      let handleAry = [];
      this.unClassifiedData[type].forEach((item)=>{
        handleAry.push(item)
      })
      switch (type) {
        case "searchNews":
          handleAry.forEach((item, index) => {
            this.leftTransferCheckbox[index] = {
              key:item.DataID,
              detailType:'news',
              typeNews:'searchNews',
              label: `${item.NewsTitle}${' , '}${this.$dayjs(item.NewsTime).format('YYYY-MM-DD')}`,
              disabled:this.disabled
            };
          });
          break;
        case "searChsan":
          handleAry.forEach((item, index) => {
            this.leftTransferCheckbox[index] = {
              key:item.DataID,
              detailType:'table',
              typeNews:'searChsan',
              label: `${item.SourceName}${' , '}${item.ListType}${' , '}${item.PublicDt}`,
              disabled:this.disabled
            };
          });
          break;
        case "searchConstr":
          handleAry.forEach((item, index) => {
            this.leftTransferCheckbox[index] = {
              key:item.DataID,
              detailType:'table',
              typeNews:'searchConstr',
              label: `${item.ComName}${' , '}${item.EffectDt}${' , '}${item.DeadlineDt}`,
              disabled:this.disabled
            };
          });
          break;
        case "searchLabor":
          handleAry.forEach((item, index) => {
            this.leftTransferCheckbox[index] = {
              key:item.DataID,
              detailType:'table',
              typeNews:'searchLabor',
              label: `${item.UnitEntity}${' , '}${item.VioLaw}${' , '}${item.PunishDt}`,
              disabled:this.disabled
            };
          });
          break;
        case "searchEnviroment":
          handleAry.forEach((item, index) => {
            this.leftTransferCheckbox[index] = {
              key:item.DataID,
              detailType:'table',
              typeNews:'searchEnviroment',
              label: `${item.UnitName}${' , '}${item.PunishTime}`,
              disabled:this.disabled
            };
          });
          break;
      }
    },
    /*處理transfer的右邊(候選新聞)*/
    produceCandidateNews() {
      if(this.existingNewsID[this.typeNews].length>0){
        let rightTransferChecked = this.leftTransferCheckbox.filter((item) => {
          return !this.existingNewsID[this.typeNews].includes(item.key); 
        });
        this.candidateNewsID[this.typeNews] = rightTransferChecked.map((item) => item.key);
      }else{
        this.candidateNewsID[this.typeNews] = this.leftTransferCheckbox.map((item) => item.key);
      }
    },
    doGetNewsData(typeNews,data) {
      let requestData = {
        DataID:[],
        Page_Size: 999, //每頁有幾筆
        Page_Num: 1, //目前頁數
      }
      requestData.DataID = data
      this.$api.data[typeNews](requestData).then((res) => {
        let dataList = [];

        if(typeNews==='searChsan'){
          dataList = res.data.map((item)=>item.SanList)
        }else{
          dataList = res.data
        }

        if(this.unClassifiedData[typeNews].length===0){
          this.unClassifiedData[typeNews] = dataList
        }else{
          let unClassifiedDataID = this.unClassifiedData[typeNews].map((item)=>(item.DataID))
          dataList.forEach((item)=>{
            if(!unClassifiedDataID.includes(item.DataID)){
              this.unClassifiedData[typeNews].push(item)
            }
          })
        }
        
        if(typeNews === this.typeNews){
          this.handleNewsDataClassification()
        }
      });
    },
    doGetInfo(ListID,type){
      return new Promise((resolve) => {
        this.$api.list[type]({ListID:ListID}).then((res) => {
          console.log(res);
          let {News,Constr,Sanction,Labor,Enviroment} = {...res}
          if(News.length>0){
            //負面新聞資料
            this.existingNewsID.searchNews = News
            this.doGetNewsData('searchNews',News)
          } 
          if(Constr.length>0){
            //政府拒絕往來廠商名單
            this.existingNewsID.searchConstr = Constr
            this.doGetNewsData('searchConstr',Constr)
          }
          if(Sanction.length>0){
            //聯合國制裁名單
            this.existingNewsID.searChsan = Sanction
            this.doGetNewsData('searChsan',Sanction)
          }
          if(Labor.length>0){
            //違反勞動法名單
            this.existingNewsID.searchLabor = Labor
            this.doGetNewsData('searchLabor',Labor)
          }
          if(Enviroment.length>0){
            //環保署裁罰處分名單
            this.existingNewsID.searchEnviroment = Enviroment
            this.doGetNewsData('searchEnviroment',Enviroment)
          }
          resolve()
        });
      });
    },
  },
  mounted(){
    if(this.$route.query.typeNews){
      this.typeNews = this.$route.query.typeNews

      if(this.$route.query.DataID){
        // this.formQueryDataID = JSON.parse(this.$route.query.DataID)
        this.unClassifiedData = JSON.parse(this.$route.query.DataID)
        this.handleNewsDataClassification()
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.tabNews {
  // margin-top: 5%;
  .tabsWrap {
    width: 90%;
    margin: auto;
  }
  .newsWrap {
    // text-align: center;
    width: 90%;
    margin: auto;
    ::v-deep .transfer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .el-transfer-panel {
        width: 40%;
      }
      .el-transfer__buttons{
        width: 20%;
        text-align: center;
      }
      .el-checkbox__label{
        cursor: pointer;
        &:hover{
          color: blue;
        }
      }
    }
  }

  .search {
    width: 90%;
    margin: auto;
    text-align: right;
    margin-bottom: 10px;
  }
  .searchRelationModal{
    .searchWrap{
      .el-input{
        margin: 0px 5px 10px 5px;
      }
    }
  }
  .goDetailPage{
    text-decoration: underline;
    color: blue;
  }
  a{    
    cursor: pointer;
  }
}
</style>
