var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "approvalRecord" },
    [
      _vm.recordData.length !== 0
        ? _c("section", [
            _c("div", { staticClass: "recordWrap" }, [
              _c("p", [_vm._v("簽核歷程：")]),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _vm._m(0),
                  _vm._l(_vm.recordData, function(item, index) {
                    return _c("div", { key: index, staticClass: "text" }, [
                      _c("div", { staticClass: "itemText" }, [
                        _vm._v(
                          _vm._s(
                            item.CreateUser
                              ? item.CreateUser
                              : "此使用者無設定名字"
                          )
                        )
                      ]),
                      _c("div", { staticClass: "itemText" }, [
                        _vm._v(_vm._s(_vm.filterDate(item.CreateDt)))
                      ]),
                      _c("div", { staticClass: "itemText" }, [
                        _vm._v(_vm._s(item.Memo ? item.Memo : "無"))
                      ])
                    ])
                  })
                ],
                2
              )
            ])
          ])
        : _vm._e(),
      _vm.routeName !== "relatedNews"
        ? _c("el-form", { attrs: { model: _vm.suggestion } }, [
            (_vm.caseStatus === "edit" &&
              _vm.role === "operator" &&
              _vm.permission === "True") ||
            _vm.caseStatus === "add"
              ? _c("section", [
                  _c("div", { staticClass: "suggestionWrap" }, [
                    _c("div", [
                      _c("p", [_vm._v("送審備註：")]),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.suggestion.operator,
                            expression: "suggestion.operator",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "text",
                        attrs: { name: "", id: "", cols: "30", rows: "10" },
                        domProps: { value: _vm.suggestion.operator },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.suggestion,
                              "operator",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ])
                  ])
                ])
              : _vm._e(),
            (_vm.caseStatus === "wait_approval" && _vm.role === "manager") ||
            (_vm.caseStatus === "wait_delete" && _vm.role === "manager")
              ? _c("section", [
                  _c("div", { staticClass: "suggestionWrap" }, [
                    _c("div", [
                      _c("p", [_vm._v("簽核意見：")]),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.suggestion.manager,
                            expression: "suggestion.manager",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "text",
                        attrs: { name: "", id: "", cols: "30", rows: "10" },
                        domProps: { value: _vm.suggestion.manager },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.suggestion,
                              "manager",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ])
                  ])
                ])
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("p", { staticClass: "itemTitle" }, [_vm._v("申請人/覆核人")]),
      _c("p", { staticClass: "itemTitle" }, [_vm._v("送審時間/覆核時間")]),
      _c("p", { staticClass: "itemTitle" }, [_vm._v("備註")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }