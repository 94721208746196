<template>
  <div class="uploadAttachment">
    <div v-if="!disabled" class="filesLimit"><span>上傳檔案請勿超過20MB</span></div>
    <div class="addUploadWrap">
      <el-button type="success" icon="el-icon-plus" :disabled="disabled" @click="addInputFile">新增上傳欄位</el-button>
    </div>
    <el-form :model="files">
      <section class="loopWrap" v-for="(eachFile,index) in files.Attachement" :key="index">
        <div class="uploadBtnWrap">
          <div v-if="!disabled">
            <label :for="index" class="uploadStyle">
              <span>上傳</span>
              <i class="el-icon-upload2"></i>
            </label>
            <input class="inputBtn" :id="index" type="file" @change="recordFile($event,index,eachFile)">
          </div>
          <div v-if="disabled && files.Attachement[0].AttUrl">
            <el-button type="primary" icon="el-icon-download" @click="downloadFiles(eachFile)">下載</el-button>  
          </div>
        </div>
        
        <div class="fileInfo">
          <p class="order">附件{{index+1}}</p>
          <el-input v-model.trim="eachFile.AttName" placeholder="檔案名稱" :disabled="true"/>
          <el-input v-model.trim="eachFile.Remark" placeholder="備註說明" :disabled="disabled"/>
        </div>
      
        <div>
          <el-button type="danger" icon="el-icon-delete" :disabled="disabled" @click="delInputFile(index)"></el-button>
        </div>
      </section>
    </el-form>
  </div>
</template>

<script>
  export default {
    props: {
      status:{
        type: String,
      },
      data:{
        type: Array,
      },
      uploadFileFlag:{
        type:Boolean
      }
    },
    computed:{
      disabled(){
        let result = null;
        if(this.status==='addStatus'||this.status==='editStatus'){
          result = false
        }else if(this.status==='previewStatus'){
          result = true
        }
        return result
      },
      // getFilesData(){
      //   let array = [];
      //   array = JSON.parse(JSON.stringify(this.data))
      //   return array
      // }
    },
    watch:{
      data:{
        handler(data){
          let filesData = JSON.parse(JSON.stringify(data));
          if(filesData.length > 0){
            if(filesData.length>0){
              this.files.Attachement = filesData;
              this.files.Attachement.forEach((item)=>{
                item.file = ""
              })
            }
          }
        },
        deep:true,
      },
      uploadFileFlag:{
        handler(val){
          if(val){
            this.emitRecordFiles();
          }
        }
      }
    },

    data() {
      return {
        files:{
          Attachement:[
            {
              ID: "",
              file: "",
              AttName: "",
              AttUrl:"",
              Remark:""
            },
          ],
        },
      }
    },
    methods: {
      emitRecordFiles(){
        let checkedHaveValue = this.files.Attachement.filter((item)=>{
          if(!!item.file || !!item.ID || !!item.Remark){
            return item
          }
        })
        this.$emit('formData',checkedHaveValue)
      },
      recordFile(e,index,item){
        console.log(e,index,item);
        let file_MB = e.target.files[0].size/1024/1024
        if(file_MB>20){
          this.$message({
            message: '檔案請勿超過20MB',
            type: 'warning'
          });
        }else{
          this.files.Attachement[index].file = e.target.files[0]
          this.files.Attachement[index].AttName = e.target.files[0].name
        }
      },
      addInputFile(){
        this.files.Attachement.push({
          ID: "",
          file: "",
          AttName: "",
          AttUrl:"",
          Remark:""
        })
      },
      delInputFile(index){
        this.files.Attachement.splice(index,1)
      },
      downloadFiles(eachFile){
        let requestData ={
          Files:[]
        }
        requestData.Files.push({
          FileName:eachFile.AttName,
          FilePath:eachFile.AttUrl
        })
        this.$api.list.download(requestData).then((res)=>{
          const downloadElement = document.createElement('a');
          const href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.Files[0].FileEncode}`; // 創建下載的鏈接
          console.log(href);
          downloadElement.href = href;
          downloadElement.download = res.Files[0].FileName; // 下載後文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); // 點擊下載
          document.body.removeChild(downloadElement); // 下載完成移除元素
          window.URL.revokeObjectURL(href); // 釋放掉 blob 對象
        })
      },
    },
    mounted() {
    
    },
  }
</script>

<style lang="scss" scoped>
  .uploadAttachment{
    margin: 50px 0px;
    .filesLimit{
      width: 90%;
      margin: auto;
      text-align: center;
      span{
        color: orange;
      }
    }
    .addUploadWrap{
      width: 90%;
      margin: 10px auto;
      text-align: center;
      
    }
    .loopWrap{
      display: flex;
      flex-wrap:nowrap;
      align-items: center;
      justify-content:center;
      width: 90%;
      margin: auto;
      .uploadBtnWrap{
        .uploadStyle{
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          width: 56px;
          height: 40px;
          border: 1px solid #191970;
          background-color: #191970;
          border-radius: 4px;
          span{
            color:white
          }
          i{
            color: white;
          }
        }
        .inputBtn{
          display: none;
        }
      }
      .fileInfo{
        display: flex;
        align-items: center;
        width: 70%;
        .order{
          width:200px;
          text-align: center;
        }
        .el-input{
          margin-right: 20px;
        }
        .name{
          width:300px;
        }
      }
      a{
        color:white ;
      }
    }
  }
</style>